import authServices from "@/services/AuthServices.js"
import privateOutlinesServices from "@/services/PrivateOutlinesServices.js"
import privateExercisesServices from "@/services/privateExercisesServices.js"

export default {
  data: () => {
    return {
      favorites: [],
      done: [],
      previewMode: false,
      loaderGoToPrivate: false,
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchDataUserFavoritesDone()
    }
  },
  computed: {
    actualPage() {
      const actual = this.contentBook.filter(elem => elem.id == this.id)
      if (actual.length > 0) {
        return actual
      } else {
        this.$f7router.navigate(`/books/${this.idBook}/`);
      }
    },
    indexPage() {
      return this.contentBook.findIndex(elem => elem.id == this.actualPage[0].id);
    },
    previosPage() {
      const previous = this.contentBook[this.indexPage - 1]
      if (previous.typeElem == 'text') {
        return `/books/${this.idBook}/text/${previous.id}/`
      }
      if (previous.typeElem == 'session') {
        return `/books/${this.idBook}/sessions/${previous.id}/`
      }
      if (previous.typeElem == 'exercise') {
        return `/books/${this.idBook}/exercises/${previous.id}/`
      }
    },
    nextPage() {
      const next = this.contentBook[this.indexPage + 1]
      if (next.typeElem == 'text') {
        return `/books/${this.idBook}/text/${next.id}/`
      }
      if (next.typeElem == 'session') {
        return `/books/${this.idBook}/sessions/${next.id}/`
      }
      if (next.typeElem == 'exercise') {
        return `/books/${this.idBook}/exercises/${next.id}/`
      }
    }
  },
  methods: {
    async goToPrivateOutline(session, bookPanel) {
      if (!this.isAuthenticated) {
        return this.$f7.toast
          .create({
            text: "The ability to add material to private outlines is only available to users who have an account in the application. Create an account now!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      }

        this.loaderGoToPrivate = true;

        const prepareData = {
          subject: session.typeElem === 'session' ? session.summary.subject : session.title,
          coach: '',
          assistant: '',
          microcycle: '',
          mesocycle: '',
          objective: '',
          coaching: '',
          individual_actions: '',
          group_activities: '',
          motor_skills: '',
          knowledge: '',
          place: '',
          age_category: bookPanel.category > 7 ? 7 : bookPanel.category,
          category: null,
          date: null,
          time: null,
          number: null,
          time_work: null,
          count_player: null,
          gear_balls: null,
          gear_goals: null,
          gear_markers: null,
          gear_cones_1: null,
          gear_cones_2: null,
          gear_poles: null,
          gear_ladders: null,
          gear_other: '',
          notes: '',
          introductory_part_1: {data: []},
          introductory_part_2: {data: []},
          main_part_1: {data: []},
          main_part_2: {data: []},
          final_part: {data: []},
          part: {data: []},
          images: {data: []},
          type_copied: 2,
          id_book: this.idBook,
          demo: bookPanel.demo
        }

        if ('qualityNotes' in session.summary) {
          session.summary.qualityNotes.forEach(note => {
            const indexOfColon = note.indexOf(':');
  
            if (indexOfColon !== -1) {
              const category = note.substring(0, indexOfColon).replace('<strong>', '').replace('</strong>', '').trim();
              const content = note.substring(indexOfColon + 1).trim();

              if (this.getLang == 'pl' ? category.includes('DZIAŁANIA INDYWIDUALNE') || category.includes('CELE GRY') : category.includes('TECHNIQUE / INDIVIDUAL ACTIONS') || category.includes('GAME GOAL')) {
                prepareData.individual_actions = content.trim();
              } else if (this.getLang == 'pl' ? category.includes('DZIAŁANIA GRUPOWE') || category.includes('DZIAŁANIA') : category.includes('TEAM / GROUP ACTIONS') || category.includes('ACTIONS')) {
                prepareData.group_activities = content.trim();
              } else if (this.getLang == 'pl' ? category.includes('MOTORYKA') || category.includes('ZASADY GRY') : category.includes('MOTOR SKILLS') || category.includes('GAME RULES')) {
                prepareData.motor_skills = content.trim();
              } else if (this.getLang == 'pl' ? category.includes('WIEDZA') || category.includes('CEL MOTORYCZNY') : category.includes('KNOWLEDGE') || category.includes('MOTOR GOAL')) {
                prepareData.knowledge = content.trim();
              } else if (this.getLang == 'pl' ? category.includes('UWAGI') : category.includes('NOTES')) {
                prepareData.notes = content.trim();
              }
            }
          });
        }

        const initJsonExercise = {
            id: null,
            topic: '',
            description: '',
            img: {
                id: 'onBooks',
                graphics: ''
            },
            mod: '',
            time: ''
        }
        let moduleOutline = []

        if (session.typeElem == 'exercise') {
          if ('part' in session) { moduleOutline.push({book: 'part', private: 'part'}) }
        }
        if (session.typeElem == 'session') {
          if ('partIntroductory' in session) { moduleOutline.push({book: 'partIntroductory', private: 'introductory_part_1'}) }
          if ('partIntroductoryI' in session) { moduleOutline.push({book: 'partIntroductoryI', private: 'introductory_part_1'}) }
          if ('partIntroductoryII' in session) { moduleOutline.push({book: 'partIntroductoryII', private: 'introductory_part_2'}) }
          if ('partMainI' in session) { moduleOutline.push({book: 'partMainI', private: 'main_part_1'}) }
          if ('partMainII' in session) { moduleOutline.push({book: 'partMainII', private: 'main_part_2'}) }
          if ('partFinal' in session) { moduleOutline.push({book: 'partFinal', private: 'final_part'}) }
        }

        moduleOutline.forEach(module => {
          session[module.book].slice().forEach((data, index) => {
            prepareData[module.private].data.push(Object.assign({}, initJsonExercise))
            prepareData[module.private].data.id = index
            if (data.content && data.content.length > 0) {
              data.content.forEach((elem) => {
                    const row = elem.replaceAll('<strong>', '').replaceAll('</strong>', '.')
                    prepareData[module.private].data[index].description += row + ' \n'
                });
            }
            if (data.mod && data.mod.length > 0) {
              data.mod.forEach((elem) => {
                prepareData[module.private].data[index].mod += elem + ' \n'
                });
            }
            if (data.name) {
              prepareData[module.private].data[index].topic = data.name
            }
            if (data.image) {
              let mainImage
              if (session.typeElem == 'session') {
                mainImage = data.image[0] ? `/static/${bookPanel.keyImage}/session-${session.id}/${data.image[0]}` : ''
              }
              if (session.typeElem == 'exercise') {
                mainImage = data.image[0] ? `/static/${bookPanel.keyImage}/${data.image[0]}` : ''
              }
              prepareData[module.private].data[index].img = {
                    id: 'onBooks',
                    graphics: mainImage
                }
            }
          });
        })
        
        try {
          if (session.typeElem == 'exercise') {
            const responseCreatePrivateExercise = await privateExercisesServices.createPrivateExercise(prepareData);
        
            if (responseCreatePrivateExercise.data.id) {
              this.$f7router.navigate(`/private-exercises/${responseCreatePrivateExercise.data.id}/`)
            }
            this.$f7.toast
            .create({
              text: "Exercise added!",
                          position: "bottom",
              
              closeTimeout: 3000
            })
            .open();
          }

          if (session.typeElem == 'session') {
            const responseCreatePrivateOutline = await privateOutlinesServices.createPrivateOutline(prepareData);
          
            if (responseCreatePrivateOutline.data.id) {
              this.$f7router.navigate(`/private-outlines/${responseCreatePrivateOutline.data.id}/`)
            }
            this.$f7.toast
            .create({
              text: "Outline added!",
                          position: "bottom",
              
              closeTimeout: 3000
            })
            .open();
          }
          
        } catch (error) {
          this.$f7.toast
            .create({
              text: `Something went wrong with the ${session.typeElem == 'session' ? 'outline' : 'exercise'} creation. Please try again or contact your administrator.`,
                          position: "bottom",
              
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
        this.loaderGoToPrivate = false;
    },
    changePreviewMode() {
        this.previewMode = !this.previewMode
    },
    prepareFavorite(session, keyImage) {
      const data = {
        "idBook": this.idBook,
        "book": session.book,
        "id": session.id,
        "title": session.title,
        "content": session.content,
        "mainImage": session.mainImage,
        "typeElem": session.typeElem,
        "keyImage": keyImage,
      }
      return data
    },
    prepareDone(session) {
      const data = {
        "idBook": this.idBook,
        "book": session.book,
        "id": session.id,
      }
      return data
    },
    showInfoFavorite(session) {
      if (session.isFavorite) {
        this.$f7.toast.create({
            text: `${session.title}: has been removed from favorites.`,
            position: 'bottom',
            closeTimeout: 1000,
        }).open();
      } else {
        this.$f7.toast.create({
          text: `${session.title}: has been added to favorites.`,
          position: 'bottom',
          closeTimeout: 1000,
        }).open();
      }
    },
    showInfoDone(session) {
      if (session.isDone) {
        this.$f7.toast.create({
          text: `${session.title}: has been marked as unread.`,
          position: 'bottom',
          closeTimeout: 1000,
        }).open();
      } else {
          this.$f7.toast.create({
            text: `${session.title}: has been marked as read.`,
            position: 'bottom',
            closeTimeout: 1500,
          }).open();
      }
    },
    toggleFavoriteSuccess(session) {
      if (session.isFavorite) {
        this.favorites = this.favorites.filter((elem) => {
          if (elem.book == session.book) {
            return elem.id !== session.id;
          }
        });
        if (this.isAuthenticated) {
          this.onFavoriteSuccess(this.favorites)
        }
      } else {
        const favorite = this.prepareFavorite(session, this.bookPanel.keyImage)
        this.favorites.push(favorite)
        if (this.isAuthenticated) {
          this.onFavoriteSuccess(this.favorites)
        }
      }
    },
    toggleDoneSuccess(session) {
      if (session.isDone) {
        this.done = this.done.filter((elem) => {
          if (elem.book == session.book) {
            return elem.id !== session.id;
          }
        });
        if (this.isAuthenticated) {
          this.onDoneSuccess(this.done)
        }
      } else {
        const done = this.prepareDone(session)
        this.done.push(done)
        if (this.isAuthenticated) {
          this.onDoneSuccess(this.done)
        }
      }
    },
    async fetchDataUserFavoritesDone() {
      try {
        const responseDataUserFavoritesDone = await authServices.userFavoritesDone();
        if (responseDataUserFavoritesDone.data.favorites == null || responseDataUserFavoritesDone.data.done == null) {
          const initData = []
          if (responseDataUserFavoritesDone.data.favorites == null) {
            this.onFavoriteSuccess(initData)
          }
          if (responseDataUserFavoritesDone.data.done == null) {
            this.onDoneSuccess(initData)
          }
        } else {
          this.favorites = responseDataUserFavoritesDone.data.favorites
          this.done = responseDataUserFavoritesDone.data.done
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$f7.toast
          .create({
            text: "Your session has expired. You must log in again.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
          this.onLogout();
        } else {
          this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        }
        console.log(error);
      }
    },
    async onFavoriteSuccess(favorites) {
      try {
        const responseDataFavorites = await authServices.updateFavorites(favorites);
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    async onDoneSuccess(done) {
      try {
        const responseDataDone = await authServices.updateDone(done);
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    },
    prepareData(data) {
      this.book = data.data
      let prepareContentJson = null
      let prepareMenuJson = null

      if (this.getLang == 'pl') {
        if (data.data.content == null) {
          prepareContentJson = data.data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
          prepareMenuJson = data.data.menu_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
        } else {
          prepareContentJson = data.data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
          prepareMenuJson = data.data.menu.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
        }
      } 
      
      if (this.getLang == 'en') {
        if (data.data.content_ang == null) {
          prepareContentJson = data.data.content.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
          prepareMenuJson = data.data.menu.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
        } else {
          prepareContentJson = data.data.content_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
          prepareMenuJson = data.data.menu_ang.replaceAll('False','false').replaceAll('True', 'true').replaceAll( /'/g,'"')
        }
      }

      this.contentBook = JSON.parse(prepareContentJson).data
      this.contentMenu = JSON.parse(prepareMenuJson).data
      this.bookPanel = {
        "title": this.book.title,
        "title_ang": this.book.title_ang,
        "demo": this.book.demo,
        "id": this.book.id,
        "shop": this.book.shop,
        "cover": this.book.cover,
        "description": this.book.description,
        "keyImage": this.book.key_image,
        "company": this.book.company,
        "category": this.book.category,
      }
    },
    async fetchDataBook() {
      try {
        this.loader = true;
        
        const responseDataBooks = await authServices.book(this.getActualBook);
        if (responseDataBooks.data == "") {
          this.$f7router.navigate("/not-found/");
        } else {
          this.prepareData(responseDataBooks)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong with the publication. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
        .open();
        console.log(error);
      }
      this.loader = false;
    },
    async fetchDataBookDemo() {
      try {
        this.loader = true;

        const responseDataBooks = await authServices.bookDemo(this.getActualBook);
        if (responseDataBooks.data == "") {
          this.$f7router.navigate("/not-found/");
        } else {
          this.prepareData(responseDataBooks)
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);

      }
      this.loader = false;
    },
    onLogout() {
      this.$store.dispatch("appkonspektypilkanozna/setToken", null);
      this.$store.dispatch("appkonspektypilkanozna/setVerified", false);
      this.$store.dispatch("appkonspektypilkanozna/setRole", null);
      this.$store.dispatch("appkonspektypilkanozna/setGlobalId", null);
      this.$f7.toast.create({
          text: 'Your account has been logged out of the application',
          position: 'bottom',
          closeTimeout: 3000,
      }).open();
      this.$f7.views.main.router.navigate('/login/')
    },
  }
}