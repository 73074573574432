<template>
  <f7-page :login-screen="!previewMode" :class="{ 'previewMode pageCreateSession': previewMode }" class="no-print pageCreateSession">
    <navbarbook></navbarbook>
    <loader v-if="loader"></loader>

    <div v-else>
      <div v-if="actualSession !== undefined">
      <f7-block v-if="!previewMode" style="margin-top: 0px">

        <f7-toolbar tabbar labels bottom style="border-radius: 0px 0px 10px 10px">
          <f7-link :tab-link="`#exerciseTemat${id}`" tab-link-active>{{ 'outline.subject' | translate }}</f7-link>
          <f7-link :tab-link="`#exerciseOpis${id}`">{{ 'outline.description' | translate }}</f7-link>

        </f7-toolbar>
        <f7-tabs>

      <f7-tab :id="`exerciseTemat${id}`" class="page-content" tab-active style="padding-top: 10px; padding-bottom: 0px">
        <f7-card class="sessionCard">
            <f7-card-header>
              <div class="left">
                  {{actualSession[0].title}}
              </div>
            </f7-card-header>
        </f7-card>
        <div>
          <f7-block>
            <div v-if="actualSession[0].summary.pointCoach !== undefined">
              <strong class="text-uppercase">{{ 'outline.coachingPoints' | translate }}: </strong>
              <p class="text-justify" v-for="point in actualSession[0].summary.pointCoach" :key="point.id" v-html="point"></p>
            </div>
            <div v-if="actualSession[0].summary.rememberCoach !== undefined && actualSession[0].summary.rememberCoach.length !== 0">
              <strong class="text-uppercase">{{ 'outline.rememberCoach' | translate }}: </strong>
              <p class="text-justify" v-for="point in actualSession[0].summary.rememberCoach" :key="point.id" v-html="point"></p>
            </div>
          </f7-block>
        </div>
      </f7-tab>
      <f7-tab :id="`exerciseOpis${id}`" class="page-content" style="padding-top: 10px; padding-bottom: 0px">
        <div>
          <div class="part" v-for="part in actualSession[0].part" :key="part.id">
            <f7-block class="content">
              <p class="text-justify" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
            </f7-block>
            <f7-block v-if="part.image.length > 0" class="image">
              <div v-for="image in part.image" :key="image.id">
                <img class="w-100" :src="`static/${bookPanel.keyImage}/${image}`">
              </div>
            </f7-block>
            <f7-block v-if="part.hasOwnProperty('imageMod')" class="image">
                <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                  <img class="w-100" :src="`static/${bookPanel.keyImage}/${imageMod}`">
                </div>
              </f7-block>  
            <f7-block v-if="part.animation !== undefined && part.animation.length > 0" class="image text-center">
              <div v-for="animation in part.animation" :key="animation.id">
                <video id="video-player" width="100%" controls loop muted>
                  <source :src="`static/${bookPanel.keyImage}/${animation}`" type="video/mp4">
                </video>
              </div>
            </f7-block>
          </div>
        </div>
      </f7-tab>


        </f7-tabs>
      </f7-block>

      <main v-else id="outlineViewport">
          <div class="wrapperBox">
            <f7-block>
              <div class="sectorMain">
                 <div v-if="actualSession[0].title !== undefined">
                  <div class="d-flex w-100 box-row" :class="actualSession[0].summary.pointCoach !== undefined && actualSession[0].summary.rememberCoach !== undefined ? 'no-border-bottom' : ''">
                    <div class="box-col box-col-medium text-center border-right box-subject">
                      <p class="text-uppercase">{{ 'outline.name' | translate }}</p>
                      <span class="print">{{actualSession[0].title}}</span>
                    </div>
                  </div>
                </div>

              <div v-if="actualSession[0].summary.pointCoach !== undefined">
                <div class="d-flex w-100 box-row" :class="actualSession[0].summary.rememberCoach !== undefined && actualSession[0].summary.rememberCoach.length !== 0 ? 'no-border-bottom' : ''">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.coachingPoints' | translate }}</p>
                    <div class="wrapperSpan">
                      <span class="print" v-for="point in actualSession[0].summary.pointCoach" :key="point.id" v-html="point" :class="{'minHeight40' : actualSession[0].summary.pointCoach.length == 1}"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="actualSession[0].summary.rememberCoach !== undefined && actualSession[0].summary.rememberCoach.length !== 0">
                <div class="d-flex w-100 box-row">
                  <div class="box-col box-col-large text-center border-right">
                    <p class="text-uppercase">{{ 'outline.rememberCoach' | translate }}</p>
                    <div class="wrapperSpan">
                      <span class="print" v-for="point in actualSession[0].summary.rememberCoach" :key="point.id" v-html="point" :class="{'minHeight40' : actualSession[0].summary.rememberCoach.length == 1}"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </f7-block>
        </div>
        <aside v-if="actualSession[0].part !== undefined" class="wrapperIntroduction1 wrapperPart">
            <f7-block class="wrapperColumn">
              <div>
                  <div class="print wrapperPartName">
                    <h5 class="partName text-uppercase">{{ 'outline.exercise' | translate }}</h5>
                  </div>
              </div>
                <div class="w-100">
                    <f7-row class="wrapperRow" v-for="part in actualSession[0].part" :key="part.id">
                        <f7-col class="content" width="60">
                            <p v-if="part.name" class="print font-weight-bold text">{{part.name}}</p>
                            <p class="print text white-space-pre-wrap text-left" v-for="paragraph in part.content" :key="paragraph.id" v-html="paragraph"></p>
                        </f7-col>
                      <f7-col class="imageOnExercise" width="40">
                                <div class="wrapperImageExercise">
                                  <div v-for="image in part.image" :key="image.id">
                                    <img class="w-100" :src="`static/${bookPanel.keyImage}/${image}`">
                                  </div>
                                  <div v-if="part.hasOwnProperty('imageMod')">
                                    <div v-for="imageMod in part.imageMod" :key="imageMod.id">
                                      <img class="w-100" :src="`static/${bookPanel.keyImage}/${imageMod}`">
                                    </div>
                                  </div>  

                                  <div v-if="part.animation !== undefined && part.animation.length > 0" class="text-center">
                                    <div v-for="animation in part.animation" :key="animation.id">
                                      <video id="video-player" width="100%" controls loop muted>
                                        <source :src="`static/${bookPanel.keyImage}/${animation}`" type="video/mp4">
                                      </video>
                                    </div>
                                  </div>
                                </div>
                        </f7-col>
                    </f7-row>
                  </div>
          </f7-block>
            </aside>
    </main>

    <f7-block style="max-width: 450px; margin: 0 auto">
      <f7-row class="mb-2">
          <f7-col width="50">
            <f7-button v-if="indexPage !== 0" style="margin: 0 12px;" @click="$f7router.navigate(`${previosPage}`);" raised>{{ 'books.previous' | translate }}</f7-button>
          </f7-col>
          <f7-col width="50">
            <f7-button v-if="indexPage !== contentBook.length - 1" style="margin: 0 12px;" @click="$f7router.navigate(`${nextPage}`);" raised>{{ 'books.next' | translate }}</f7-button>
          </f7-col>
        </f7-row>
        <div v-if="bookPanel.demo">
          <div v-if="bookPanel.shop !== '' && getLang === 'pl'">
            <f7-link style="margin: 0 12px;" :href="bookPanel.shop" external target="_blank">
              <p class="text-center font-weight-bold" style="color: #d62029">{{ 'global.more_buy_single' | translate }}.</p>
            </f7-link>
            <p class="text-uppercase text-center mt-0 mb-0">{{ 'global.or' | translate }}</p>
          </div>
          <f7-link style="margin: 0 12px;" href="/payments/">
            <p class="text-center font-weight-bold" style="color: #0fa300">{{ 'global.more_buy_subscriptions' | translate }}.</p>
          </f7-link>
        </div>
    </f7-block>
    </div>

    <div class="custom-fab">
        <div v-if="actualSession !== undefined && actualSession[0].isDone !== undefined" class="box cursor-pointer" @click="toggleDone(actualSession[0])">
          <f7-link v-if="actualSession[0].isDone" :tooltip="'books.done_false' | translate">
              <img height="15px" :src="'/static/icons/done-full.svg'" />
            </f7-link>
            <f7-link v-else :tooltip="'books.done_true' | translate">
              <img height="15px" :src="'/static/icons/done-empty.svg'" />
            </f7-link>
        </div>
        <div v-if="actualSession !== undefined && actualSession[0].isFavorite !== undefined" class="box cursor-pointer" @click="toggleFavorite(actualSession[0])">
          <f7-link v-if="actualSession[0].isFavorite" :tooltip="'books.favorite_false' | translate">
              <img height="15px" :src="'/static/icons/star-full.svg'" />
            </f7-link>
            <f7-link v-else :tooltip="'books.favorite_true' | translate">
              <img height="15px" :src="'/static/icons/star-empty.svg'" />
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="changePreviewMode()">
            <f7-link v-if="previewMode" :tooltip="'outline.goToTab' | translate" >
              <img height="20px" src="/static/icons/tabs.svg" alt="">
            </f7-link>
            <f7-link v-else :tooltip="'outline.previewMode' | translate" >
              <img height="20px" src="/static/icons/outline.svg" alt="">
            </f7-link>
        </div>
        <div class="box cursor-pointer" @click="goToPrivateOutline(actualSession[0], bookPanel)" :disabled="!loaderGoToPrivate">
            <loader v-if="loaderGoToPrivate" :color="'white'" class="loader"></loader>
            <f7-link v-else :tooltip="'outline.goToPrivateOutline' | translate" >
              <img height="20px" src="/static/icons/select-arrow.svg" alt="">
            </f7-link>
        </div>
      </div>
    </div>

  </f7-page>
</template>

<script>
import Navbarbook from "@/components/Navbar/Navbarbook.vue";
import Loader from "@/components/Loader";
import mixinBook from "@/mixins/mixinBook"

export default {
  mixins: [mixinBook],
  components: {
    Navbarbook,
    Loader
  },
  props: {
    idBook: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      loader: false,
      contentBook: [],
      bookPanel: {}
    };
  },
  computed: {
    actualSession() {
      let actual = this.contentBook.filter(elem => elem.id == this.id)
      if (actual.length > 0) {
        if (this.favorites.length == 0) {
          actual[0].isFavorite = false
        } else {
          this.favorites.forEach(favorite => {
            actual[0].isFavorite = favorite.book == actual[0].book && favorite.id == actual[0].id
          });
        }

        if (this.done.length == 0) {
          actual[0].isDone = false
        } else {
          this.done.forEach(done => {
            actual[0].isDone = done.book == actual[0].book && done.id == actual[0].id
          });
        }

        return actual
      } else {
        this.$f7router.navigate(`/books/${this.idBook}/`);
      }
    }
  },
  mounted() {
    if ( window.innerWidth >= 800 ) {
      this.previewMode = true;
    }
    this.$store.dispatch("appkonspektypilkanozna/setActualBook", this.idBook);
    if (this.isAuthenticated) {
      this.fetchDataBook()
    } else {
      this.fetchDataBookDemo()
    }
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
  methods: {
    toggleFavorite(session) {
      if (!this.isAuthenticated) {
        return this.$f7.toast
          .create({
            text: "The ability to add materials to favorites is only available to users who have an account in the application. Create an account now!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      }
      this.showInfoFavorite(session);
      this.toggleFavoriteSuccess(session);
    },
    toggleDone(session) {
      if (!this.isAuthenticated) {
        return this.$f7.toast
          .create({
            text: "The ability to add materials as read is only available to users who have an account in the application. Create an account now!",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
      }
      this.showInfoDone(session);
      this.toggleDoneSuccess(session);
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/session.scss";
</style>