export default {
  global: {
    all: 'All',
    all2: 'All',
    new: 'New',
    diet: 'Diet',
    sms: 'SMS',
    historySms: 'SMS history',
    match: 'Matches',
    player_training: 'Training at home',
    announcement: 'Preview',
    offer_in_preparation: 'Offer in preparation',
    coaching_notepad: 'Coaching notepad',
    coaching_notepad_description: 'is the ability to manage the club.',
    coaching_notepad_publications: '📚 Publications',
    coaching_notepad_publications_description_1: 'The „Publications” module is a virtual knowledge repository for soccer coaches. It contains paid materials from the leading coaching brands in Poland, providing access to over 60 publications, which equates to over 2,000 outlines and exercises. These materials are available for every age category, from the youngest players (U4) to senior soccer.',
    coaching_notepad_publications_description_2: 'Wealth of materials: The „Publications” database includes materials from renowned coaching brands, ensuring access to the latest and most valuable content.',
    coaching_notepad_publications_description_3: 'Customization for individual outlines: Materials from „Publications” can be used to create private outlines plans and saved in your own database, allowing for personalized training tailored to the team`s needs.',
    coaching_notepad_publications_description_4: 'Customization for individual outlines: Materials from „Publications” can be used to create private outlines plans and saved in your own database, allowing for personalized training tailored to the team`s needs.',
    coaching_notepad_publications_description_5: 'For all age categories: The materials available in „Publications” cover a wide range of age categories, making it possible to find content suitable for every player group.',
    coaching_notepad_private_outlines: '🗂️ Private Outlines',
    coaching_notepad_private_outlines_description_1: 'The „Private Outlines” feature is a central repository of training materials and exercises created using the module, enabling the creation, organization, and access to valuable training materials. This tool allows for categorizing materials and creating personalized lesson plans and exercises.',
    coaching_notepad_private_outlines_description_2: 'Individual database of outlines plans: Create and store outlines plans and exercises in one place, tailored to your needs.',
    coaching_notepad_private_outlines_description_3: 'Material categorization: Organize materials by categorizing them by topics, difficulty levels, or other criteria.',
    coaching_notepad_private_outlines_description_4: 'Printing and saving to PDF: Materials can be printed or saved in PDF format, allowing for convenient use during training sessions.',
    coaching_notepad_private_outlines_description_5: 'Standard training element division: Outlines plans and exercises are prepared according to a standard, making it easier to create and customize training sessions.',
    coaching_notepad_private_outlines_description_6: 'The „Private Outlines” feature is a tool that assists coaches in creating and organizing training materials. It provides full control over training resources, allowing for the customization of training to meet the team`s needs and achieve better results on the field.',
    coaching_notepad_calendar: '📆 Calendar',
    coaching_notepad_calendar_description_1: 'Our calendar module is the heart of your soccer club. Here, you`ll find all the essential information for the club and groups associated with organizing sessions. It`s more than just a calendar; it`s a tool for professional training management and efficient planning.',
    coaching_notepad_calendar_description_2: 'Full month in one view: View the entire month for a specific group on a single screen. It`s the perfect tool for real-time monitoring of training schedules and other club events.',
    coaching_notepad_calendar_description_3: 'Quick access to training: No more wasting time searching through old notes or lengthy planning. The calendar provides you with quick access to training sessions - just click, and you`re ready to go.',
    coaching_notepad_calendar_description_4: 'Attendance tracking: Monitor player attendance in a simple and transparent way. Save time on manual attendance counting.',
    coaching_notepad_calendar_description_5: 'Plan training sessions: Create and plan training sessions with ease. Add exercises, set goals, and save notes to ensure each training is tailored to your team`s needs.',
    coaching_notepad_calendar_description_6: 'With the calendar module in our application, managing training and club organization is now easier and more effective than ever before. Take control of your schedule and focus on improving your team.',
    coaching_notepad_information_board: '🌐 Information Board',
    coaching_notepad_information_board_description_1: 'The „Information Board” feature is a tool that brings clarity and convenience to tracking training schedules. It`s not just a calendar; it`s a window into our soccer club, available on any website as an iframe or link.',
    coaching_notepad_information_board_description_2: 'Training calendar on your website: Now you can place our training calendar on your website. This means that anyone associated with the club can stay up to date with the training schedule without the need to access the application.',
    coaching_notepad_information_board_description_3: 'Clarity at a glance: With the „Information Board,” every participant can see which training sessions are taking place and when. It`s an excellent tool for planning one`s time and ensuring that no one misses any training.',
    coaching_notepad_information_board_description_4: 'Easy customization and sharing: This feature gives you control over the calendar`s appearance on your website. Customize it to your needs and share it with all interested parties.',
    coaching_notepad_information_board_description_5: 'Now you don`t have to worry about anyone missing a training session. The „Information Board” feature allows real-time tracking of training sessions, making club management even more user-friendly and efficient. Provide your members with easy access to training information through this convenient function.',
    coaching_notepad_group: '🧑‍🤝‍🧑 Groups',
    coaching_notepad_group_description_1: 'The „Groups” feature is the key to organizing and personalizing your training in our application. This tool allows you to create training groups with their own labels that will identify a specific group in various modules of the application.',
    coaching_notepad_group_description_2: 'Creating training groups: Now you can group your players into convenient training units. Creating groups is quick and intuitive.',
    coaching_notepad_group_description_3: 'Color customization: Each group can have its own primary identifying color. This not only makes recognition easier but also gives the application a more personalized character.',
    coaching_notepad_group_description_4: 'Quick search: With groups, you can find the necessary information more quickly. Search by group color and easily locate the relevant data.',
    coaching_notepad_group_description_5: 'With the „Groups” feature, managing multiple training groups becomes more transparent and efficient. Each group has its own color, which helps with quick recognition and identification of the relevant data. It`s a tool that makes working with the application even more tailored to your needs.',
    coaching_notepad_players: '👤 Players',
    coaching_notepad_players_description_1: 'The „Players” feature is the heart of your soccer club in one place. Here, you`ll find a complete list of players in a specific age group, as well as all the essential information crucial for club management.',
    coaching_notepad_players_description_2: 'Full player list: With the „Players” feature, you have access to a complete list of players in a specific age group. It`s the perfect tool for monitoring the team`s roster.',
    coaching_notepad_players_description_3: 'CRM information: All necessary player information, such as contact details and more, is gathered in one place. This makes managing them incredibly efficient.',
    coaching_notepad_players_description_4: 'Quick search: With the search feature, you can find the necessary information faster. Search by name or other criteria.',
    coaching_notepad_players_description_5: 'The „Players” feature gives you control over player data and information. It`s a tool that simplifies team management, allows you to track player progress, and attend to their needs. With „Players,” you have a comprehensive overview of your team in one place.',
    coaching_notepad_membership_dues: '💵 Membership dues',
    coaching_notepad_membership_dues_description_1: 'The „Membership dues” module is a crucial tool for managing payments for sessions in your soccer club. Here, you control and generate payment schedules, ensure regular contributions, and maintain transparency in the club`s finances.',
    coaching_notepad_membership_dues_description_2: 'Payment schedule generation: Create payment schedules that align with your club`s calendar. Effortlessly generate payment schedules, such as monthly plans.',
    coaching_notepad_membership_dues_description_3: 'Tracking completed payments: With the „Membership Fees” module, you stay up to date with payments. Mark payments as they are made to have a comprehensive overview of the club`s finances.',
    coaching_notepad_membership_dues_description_4: 'Financial transparency: Gain full control over the club`s finances. Monitor payment statuses, generate reports, and ensure that all fees are settled.',
    coaching_notepad_membership_dues_description_5: 'The „Membership dues” module simplifies the management of a soccer club`s finances. It allows you to maintain control over payments, enabling you to focus on developing the team and improving training, knowing that the finances are in order.',
    coaching_notepad_training_sessions: '🕒 Training Sessions',
    coaching_notepad_training_sessions_description_1: 'The „Training Sessions” module is the central hub for your soccer sessions, where you can create and manage every aspect of training. This tool allows for comprehensive planning, organization, and tracking of training sessions in your soccer club.',
    coaching_notepad_training_sessions_description_2: 'Location and field: Add information about the training location, including field details. This ensures that all participants know where the training will take place.',
    coaching_notepad_training_sessions_description_3: 'Start and end times: Specify the exact start and end times of the training session. This enables precise scheduling.',
    coaching_notepad_training_sessions_description_4: 'Full description: Include a description of the training session that contains all the necessary information about the training plan, objectives, and session details.',
    coaching_notepad_training_sessions_description_5: 'Linking lesson plans: The „Training Sessions” module allows for seamless attachment of lesson plans, aiding in maintaining consistency and access to training materials.',
    coaching_notepad_training_sessions_description_6: 'In the group training calendar: All training session information is available in the calendar of the respective training group, making it easy for participants to access the session schedule.',
    coaching_notepad_training_sessions_description_7: 'The „Training Sessions” module is a comprehensive tool for training management, facilitating the creation, organization, and sharing of training plans. It provides complete control over every aspect of training, helping in skill development and achieving goals in your soccer club.',
    coaching_notepad_training_planning: '🎯 Training Planning',
    coaching_notepad_training_planning_description_1: 'The „Training Planning” module is a crucial tool for achieving training goals on every training microcycle. Here, you define objectives and track progress for each training group, all accessible in the calendar, keeping you informed of each training group`s current status.',
    coaching_notepad_training_planning_description_2: 'Microcycle goals: In the „Training Planning” module, you establish goals for each training microcycle. This provides clear guidelines on what you aim to achieve in a given period.',
    coaching_notepad_training_planning_description_3: 'Individual goals: You can customize goals independently for each training group. This means that training is tailored to the specific needs of each team.',
    coaching_notepad_training_planning_description_4: 'Progress calendar: All goals and progress are visible in the calendar. This allows you to continually monitor how each training group is working toward their objectives.',
    coaching_notepad_training_planning_description_5: 'The „Training Planning” module helps you precisely manage training goals and monitor progress. It`s a tool that enables individual customization of training to meet the unique needs of each group, ultimately translating into success on the field.',
    coaching_notepad_fields: '🏟️ Fields',
    coaching_notepad_fields_description_1: 'The „Fields” module is a tool that gives you complete control over the location of soccer sessions. Here, you create fields with all the parameters related to their location, allowing for precise tracking of where sessions take place.',
    coaching_notepad_fields_description_2: 'Field creation: With the „Fields” module, you can create fields, specifying their location, name, and other parameters. This guarantees an accurate representation of the places where training sessions occur.',
    coaching_notepad_fields_description_3: 'Comprehensive location information: Add detailed information about fields, such as the address, surface type, availability of changing rooms, and more. This aids in better preparation for training sessions.',
    coaching_notepad_fields_description_4: 'Location calendar: All fields are visible in the calendar, making it easy for participants to track where sessions are taking place.',
    coaching_notepad_fields_description_5: 'The „Fields” module provides full control over the locations of training sessions, making management and tracking of session locations more transparent and efficient. It`s a tool that helps maximize the use of available locations and ensures accuracy in organizing training sessions.',
    coaching_notepad_statistics: '📈 Statistics',
    coaching_notepad_statistics_description_1: 'The „Statistics” module is a powerful tool for analyzing and tracking results in your soccer club. It allows you to view statistics for a specific group within a defined time period, enabling control over attendance at training sessions and many other aspects.',
    coaching_notepad_statistics_description_2: 'Result analysis: The „Statistics” module enables the analysis of results, including training attendance, match outcomes, and other relevant metrics.',
    coaching_notepad_statistics_description_3: 'Timeframe customization: You can tailor the time period for which you want to conduct the statistics analysis. This allows both short-term analysis and tracking of long-term trends.',
    coaching_notepad_statistics_description_4: 'Result visualization: Statistics are presented in a clear manner using charts and graphs, making it easier to understand and interpret the results.',
    coaching_notepad_statistics_description_5: 'The „Statistics” module is a tool that helps you better understand and track progress in your soccer club. It provides access to valuable data that aids in making informed decisions and improving the club`s performance.',
    coaching_notepad_role: '🤝 Coach`s role in the club',
    coaching_notepad_role_description_1: 'In the role of a coach in our soccer application, you play a significant part in the process of creating and delivering valuable training for the players. Your tasks and permissions are tailored to effectively manage training sessions and collaborate with the club.',
    coaching_notepad_role_description_2: 'Group management: The coach has access only to assigned groups, ensuring focused management and work with specific teams.',
    coaching_notepad_role_description_3: 'Access to training sessions: The coach has the ability to view and edit training sessions assigned to them by the club owner, allowing for the customization of training to meet the team`s needs.',
    coaching_notepad_role_description_4: 'Collaboration with the club: The coach collaborates with the club, sharing their skills and knowledge in soccer. They support player development and help achieve the club`s goals.',
    coaching_notepad_role_description_5: 'Access to resources: The coach has access to publications and private lesson plans, enabling them to use valuable training materials in the training process.',
    coaching_notepad_role_description_6: 'The coach`s role is a key element in club collaboration and participation in the creation of effective training sessions. With tailored permissions, the coach can focus on developing players` skills and contributing to the team`s success.',
    coaching_notepad_sms: '📲 SMS',
    coaching_notepad_sms_description_1: 'The SMS Module is a tool that enables quick and efficient communication with athletes. It provides full control over selecting training groups and sending SMS messages directly to chosen participants, utilizing the native SMS functionality on your phone.',
    coaching_notepad_sms_description_2: 'Selection of Training Group: The SMS Module allows easy selection of training groups to which you want to send a message. You can choose the entire group or individual athletes, offering flexibility in communication.',
    coaching_notepad_sms_description_3: 'Sending Messages: Using the SMS Module, you can quickly send an SMS message directly from your phone. This is an ideal solution for conveying urgent information, such as schedule changes, training reminders, or other important announcements.',
    coaching_notepad_sms_description_4: 'Message Personalization: Add individual information to messages to make them more personalized and tailored to the specific needs of the recipients. This helps maintain engagement and better understanding of the conveyed content.',
    coaching_notepad_sms_description_5: 'Full Control Over Communication: The SMS Module gives you complete control over who receives the messages, allowing effective management of communication with athletes. This ensures that important information reaches the right people on time.',
    coaching_notepad_sms_description_6: 'The SMS Module is an invaluable tool for quick and efficient communication with athletes, ensuring that all important information is conveyed promptly. It is a way to maintain smooth communication and organization by utilizing the native SMS functions on your phone.',
    publication_zone: 'Publication zone',
    app_zone: 'App zone',
    club_zone: 'Club zone',
    coach_zone: 'Coach zone',
    player_zone: 'Player zone',
    partner_zone: 'Partner zone',
    information_zone: 'Information zone',
    privacy_policy: 'Privacy policy',
    regulations: 'Regulations',
    faq: 'FAQ',
    faq_page: 'Frequently asked questions',
    faq_private_outlines: 'Guide to private outlines',
    faq_publications: 'Guide to publications',
    faq_stripe: 'Guide to buying a subscription',
    stripe_1: 'The subscription purchase process takes place on a specially prepared page by the Stripe payment gateway, where we make the purchase by providing an email address and payment card details.',
    stripe_2: 'After the payment is completed, we are redirected to the view with the payment status. We receive an email with information about the granted access to the outline database',
    stripe_3: 'Each subscription publication contains a full version label.',
    stripe_4: 'There are over 1500 outlines and exercises in the database.',
    faq_pwa: 'Guide to installing the desktop application',
    pwa_0: 'Use Soccer Sessions like a native app! Content: Did you know that Soccer Sessions is available as a Progressive Web Application (PWA)? It means you can use our app directly on your mobile device without having to download it from Google Play or the App Store. To take advantage of this feature, simply open our website on your smartphone or tablet, and then add it to your home screen. Below is a brief guide on how to do it.',
    pwa_1: 'When you visit the website in the Google Chrome browser, you have the option to install Soccer Sessions as a native desktop application on your phone or computer.',
    pwa_2: 'Computer:',
    pwa_3: '1. In the top right corner of the browser, click on the three dots (Customize and control Google Chrome), then select Install Soccer Sessions App from the expanded menu. You can also choose the installation icon from the URL bar on the right side.',
    pwa_4: '2. After selecting, a prompt will appear asking if you want to install the application.',
    pwa_5: '3. After installation, the app will appear on the desktop and in the toolbar.',
    pwa_6: 'Phone:',
    pwa_7: '1. In the top right corner of the browser, click on the three dots, then select Add to Home screen from the expanded menu.',
    pwa_8: '2. After selecting, a prompt will appear asking if you want to add the app to your home screen.',
    pwa_9: '3. The app installation process will start. After completion, there will be a notification that you can launch the app, and the icon will appear on your phone`s home screen.',
    pwa_10: '4. From now on, Soccer Sessions will function like a native app from the Google Play or App Store.',
    faq_account: 'Guide to account',
    faq_partner: 'FAQ partner',
    partner: 'Partner',
    player: 'Player',
    coach: 'Coach',
    club: 'Club',
    presense: 'Presence',
    dues: 'Dues',
    about: 'About us',
    block_generator: 'Block generator',
    create_a_tournament: 'Create a tournament',
    create_an_outline: 'Create an outline',
    tactical_board: 'Tactical board',
    account: 'Account',
    your_base: 'Your base',
    coaching_magazines: 'Coaching magazines',
    library: 'Library',
    coach_jose: 'Coach José',
    coach_jose_title: 'I`m Coach Jose',
    coach_jose_write: 'Write something coach...',
    coach_jose_description_1: 'I`m currently on an internship at the best academies in the world and I`m learning to be your assistant when I return.',
    coach_jose_description_2: 'I will be able to create for you an outline for each age category, a training microcycle, and also plan work with the group and calendar in the KPN application.',
    coach_jose_description_3: 'Need some time now, see you later.',
    coach_jose_init_full_1: 'Hi, I`m Coach José and I want to be your personal assistant.',
    coach_jose_init_full_2: 'Write to me what outline, exercise you need and I will prepare the material for you.',
    // coach_jose_init_full_3: 'Name or description of the activity - age category - type of material',
    // coach_jose_init_full_4: 'Example: 1x1 Game - Leprechaun - Outline or 1x1 Game - U6 - Practice',
    coach_jose_select_plan: 'Your free query limit has been reached, you must purchase access to continue using the full version of the chat.',
    coach_jose_select_plan_buy: 'Buy a subscription for any period of time',
    coach_jose_empty_response: 'I couldn`t find any outline or exercise that matched the description that was posted. Try again!',
    coach_jose_must_login_or_register: 'Chat is only available to users with an in-app account, so please log in or create an account.',
    coach_jose_chat_history: 'Chat history',
    coach_jose_empty_chat_history: 'There are no chat queries in the database',
    coach_jose_response_subscription_outline: 'View outline',
    coach_jose_response_subscription_exercise: 'View exercise',
    coach_jose_response_user_outline: 'Outline found but cannot be viewed because you do not have an active subscription',
    coach_jose_response_user_exercise: 'Exercise found but cannot be viewed because you do not have an active subscription',
    favorite: 'Favorite',
    favorite_description_1: 'There are currently no favorite outlines or exercises.',
    favorite_description_2: 'Add an outline or exercise to your favorites by clicking on the icon.',
    publications: 'Publications',
    crm: 'Crm',
    settings: 'Settings',
    statistics: 'Statistics',
    calendar: 'Calendar',
    choose_a_role: 'Choose a role',
    pitches: 'Pitches',
    login: 'Login',
    logout: 'Logout',
    groups: 'Groups',
    wallet: 'Wallet',
    players: 'Players',
    trainings: 'Trainings',
    training_planning: 'Training planning',
    trainers: 'Trainers',
    information_board: 'Training schedule',
    home: 'Home',
    site_under_construction: 'Site under construction',
    this_functionality_will_be_available_soon: 'This functionality will be available soon ',
    contact: 'Contact',
    recommendations: 'Recommendations',
    recommendation_futsal: 'Futsal Kolobrzeg',
    recommendation_futsal_description: 'The materials that are in the database allow our trainers to develop and always be prepared for each training. The attendance list module has introduced a revolution in the club, because now at the end of the season we are able to pull out statistics and analyze them together with the outlines that were in the classes to be an even better team next season.',
    recommendation_futsal_link: 'See the Futsal Kolobrzeg project',
    recommendation_futbolovve: 'Futbolovve coaches',
    recommendation_futbolovve_description: 'Organization and order are the key to running an association like ours, and the football outlines app is designed to bring these aspects to the next level and we certainly recommend it! Contribution control, calendar management and training planning is very simple and intuitive. This application is also very helpful for trainers and managers. It has many interesting synopses in its database and despite the developed own methods based on our experience, we are not afraid to look into new training materials.',
    recommendation_futbolovve_link: 'See the Futbolovve project',
    recommendation_pzpn: 'Miłosz Stępiński – head coach of the Poland A women`s national team and Coach Educator of the Polish Football Association',
    recommendation_pzpn_description: 'It was with great joy and curiosity that I reached for the study by Mr. Klimczak and Mr. Bączyk on working with `gnomes`. On the domestic publishing market, there are several PZPN studies dealing with training at this stage, but none of them contain so many, well-developed and, above all, ready-made training outlines tested in practice. >Everyone who works with 5 and 6 year olds knows how difficult it is to lead a group, especially in organizational terms. Players and players repay the trainers with their enthusiasm and commitment to fun and games, but each class must be carefully prepared, thought out and, most importantly, adapted to the age of the participants. The elaboration Synopsis for gnomes 5-6 years meets all these requirements and will certainly be a helpful tool for everyone working with children at the first stage of training. My students of the football specialization at the University of Szczecin have this textbook as one of their mandatory ones. I would highly recommend.',
    recommendation_pzpn_link: 'View publications',
    partners: 'Partners',
    authors: 'Authors',
    authors_description_1: 'a young team that turned their passion and professional path into a business idea. They already have several excellent publications to their credit, which have been noticed by children`s football coaches from all over Poland, they can also boast of cooperation with the giant which is the Coach`s Assistant.',
    authors_description_2: 'In addition to printed books with substantive outlines and their electronic versions, the brand has also released free applications that improve the work of trainers. Among them we will find: a generator of coaching blocks with the option of adding your own logo, a tactical board for quickly presenting the scheme of playing the ball, creating a training outline in accordance with the PZPN and a wizard for creating a quick tournament for several teams playing on several pitches.',
    authors_description_3: 'If you are a coach of children`s football, you should absolutely know and follow the Soccer Sessions brand, because it responds to current coaching needs, creates innovative applications and supports coaches with substantive knowledge, often free of charge. ',
    soccer_sessions_about_1: 'was created primarily for people who conduct football training on a daily basis. Knowing how important it is to properly prepare a trainer for sports activities to make them effective and attractive, we want to share our experience.',
    soccer_sessions_about_2: 'In addition to ready-made examples of outlines, which are arranged in a logical and orderly manner adapted to the appropriate age category, we want to inspire you, the football coach.',
    soccer_sessions_about_3: 'In our publications you will find numerous exercises and modifications that can be used as material for the next training, starting with the dwarf, Żak and eagle categories. At the same time, we want the knowledge we provide to be as easily accessible to you as possible, so to meet your expectations, our publications are available both in hard copy, digital PDF and in a mobile version as a smartphone application.',
    soccer_sessions_about_4: 'In addition, on our website www.soccer-sessions.com we offer access to free knowledge, the trainer`s library and applications aimed at facilitating the work of the trainer.',
    file_name: 'File name',
    here: 'here',
    write_to_us: 'write to us',
    required: 'Required',
    not_found: 'There is no such page',
    not_found_description: 'Looks like you`re lost',
    change_role: 'No access for the selected role',
    change_role_description: 'You need to change your role in the app to get here',
    change_role_link: 'Change Role',
    no_access: 'You don`t have access',
    no_access_description: 'You must have an account in the application to get to this place',
    construction: 'Page under construction',
    construction_description: 'Feature will be available soon',
    or: 'or',
    or_contact: 'or contact',
    no_access_books_1: 'The full version of the application with all the outlines is available in our store in the form of a single purchase or purchase of access in the form of a subscription for a certain period of time.',
    no_access_books_2: 'You are also welcome to visit our website',
    buy_subscriptions: 'Buy subscriptions',
    buy_single: 'Buy single access now',
    empty_list_books: 'No publications matching the search criteria',
    more_buy_single : 'This is a demo version of a publication that does not contain all the material. If you want to buy the full version click here',
    more_buy_subscriptions : 'You can also buy access to all publications. If you want to buy a subscription click here',
    search: 'Search',
    lack: 'Lack',
    save: 'Save',
    buy_access: 'Buy access',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    jule: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    red: 'Red',
    purple: 'Purple',
    navy: 'Navy blue',
    blue: 'Blue',
    light_blue: 'Blue',
    green: 'Green',
    light_green: 'Light green',
    yellow: 'Yellow',
    orange: 'Orange',
    pink: 'Pink',
    brown: 'Brown',
    grey: 'Grey',
    add_new: 'Dodaj nowy',
    type_block_generator: 'A type of block',
    soccer: 'Football',
    futsal: 'Futsal',
    no_access_private: 'No access to private material',
    no_access_private_description: 'The material was created with full access to the application in the subscription form. If you want to have access to the materials again, you must purchase full access!',
    type_copied_2_outline: 'The material is blocked. Your subscription has expired and the outline was created with paid access',
    type_copied_2_exercise: 'The material is blocked. Your subscription has expired and the exercise was created with paid access',
    type_copied_1: 'The material is available. It was created from material to which you have purchased lifetime access',
    type_copied: 'Material copied from coaching publications'
  },
  tactic: {
    field: 'Field',
    tools: 'Tools',
    coach: 'Coach',
    goalkeeper: 'Goalkeeper',
    area_drawing: 'Area drawing',
    eraser: 'Eraser',
    ball: 'Ball',
    player_cross: 'Cross player',
    player_circle: 'Circle Player',
    change_marker: 'Change marker color,',
    futsal_red: 'Futsal red',
    futsal_white: 'Futsal white',
    field_green: 'Green field',
    field_white: 'White field'
  },
  generator: {
    training_blocks_generator: 'Training blocks generator',
    training_blocks_generator_description: 'Training blocks generator is a free application that allows any football coach to upload any logo to a selected pattern. There are currently 18 versions of training blocks to choose from, to which you can upload any graphics or logo of your football club.',
    logo: 'Logo (recommended dimensions 700px x 700px)',
    choose: 'Choose a training block',
    save_pdf: 'Save PDF',
    error: 'If you encountered errors while running the application or something is not working properly then',
    instruction: 'If you want to see how to use the application, take a look',
    regulation: 'Regulations for using the trainer blocks generator can be found'
  },
  tournament: {
    create: 'Create tournament',
    create_description: 'Create a tournament - is a free application that allows you to create your own tournament by entering any number of teams and fields.',
    logo: 'Logo (recommended dimensions 700px x 700px)',
    brand: 'Organization`s logo (recommended dimensions: 700px x 700px)',
    title: 'Title',
    description: 'Description',
    organizer: 'Organiser',
    city: 'City',
    place: 'Place',
    date: 'Date',
    day_of_the_week: 'Day of the week',
    start_time: 'Start time',
    number_of_teams: 'Number of teams',
    team: 'Team',
    field_1: '1 field',
    field_2: '2 fields (at least 4 teams)',
    field_3: '3 fields (minimum 6 teams)',
    field_4: '4 pitches (minimum 8 teams)',
    field_5: '5 pitches (minimum 10 teams)',
    field_6: '6 fields (minimum 12 teams)',
    field_7: '7 fields (minimum 14 teams)',
    field_8: '8 fields (minimum 16 teams)',
    field: 'Field',
    match_length: 'Match length',
    break_between_matches: 'Break between matches',
    prepare: 'Prepare the tournament',
    export: 'Tournament created successfully. Under the table there is a button that will allow you to save the tournament to a file.',
    when: 'When',
    break: 'Break',
    time: 'Time',
    save: 'Save tournament',
    error: 'If you encountered errors while running the application or something is not working properly then',
    instruction: 'If you want to see how to use the application, take a look',
    regulation: 'Terms of use create tournament are located',
  },
  books: {
    list: 'Publications list',
    count: 'Number of publications',
    category: 'Category',
    library: 'Library',
    search: 'Search',
    type: 'Type',
    partners: 'Partner',
    lang: 'Language',
    full: 'Full version',
    full_extend: 'Access is until the end of the subscription period, unless you have purchased a single access to the publication',
    auto_extend: 'The publication is regularly updated with new materials',
    demo: 'Demo',
    noSearch: 'No publications in English',
    U6U7: 'U6/U7',
    U8U9: 'U8/U9',
    U10U11: 'U10/U11',
    U12U13: 'U12/U13',
    U14U15: 'U14/U15',
    U16U17: 'U16/U17',
    U18U19: 'U18/U19',
    S: 'Senior',
    game: 'Game',
    motor: 'Motor skills',
    intelligence: 'Football intelligence',
    futsal: 'Futsal',
    a_collection_of_outlines: 'A collection of outlines',
    other: 'Other',
    player: 'Player',
    individual: 'Individual',
    auto: 'Updated',
    outline: 'Outline',
    exercise: 'Exercise',
    material_base: 'Materials in the database',
    empty_materials: 'No materials matching the search criteria',
    empty_publications: 'No publications matching the search criteria',
    change_filter: 'Change search filters',
    lack_materials: 'No materials purchased',
    preview: 'Preview',
    search_exercise: 'Search exercise',
    exercise_name: 'Exercise name',
    start: 'Start',
    table_of_contents: 'Table of contents',
    previous: 'Previous',
    next: 'Next',
    marked: 'Exercises marked as read',
    done_true: 'Mark as read',
    done_false: 'Unmark as read',
    favorite_true: 'Add to favorites',
    favorite_false: 'Remove from favorites',
    no_access_books_1: 'The full version of the application with all the outlines is available in our store in the form of a single purchase or purchase of access in the form of a subscription for a certain period of time.',
    no_access_books_2: 'You are also welcome to visit our website',
    book_current_translated: 'The publication is currently being translated',
    book_pl: 'Available in Polish',
    book_en: 'Available in English',
  },
  outline: {
    part: 'Part',
    introduction: 'Introductory',
    introduction1: 'Introductory I',
    introduction2: 'Introductory II',
    main1: 'Main I',
    main2: 'Main II',
    final: 'Final',
    outlines: 'Outlines',
    exercises: 'Exercises',
    logo: 'Logo',
    add: 'Add',
    title: 'Title',
    subject: 'Subject',
    description: 'Description',
    coach: 'Coach',
    assistant: 'Assistant',
    microcycle: 'Microcycle',
    category: 'Category',
    noCategories: 'No categories',
    objective: 'Objective',
    coachingPoints: 'Coaching points',
    no: 'No',
    mesocycle: 'Mesocycle',
    technique: 'Technique / individual activities',
    motorSkills: 'Motor skills',
    group: 'Group / team activities',
    knowledge: 'Knowledge',
    gameGoal: 'Game goal',
    actions: 'Actions',
    gameRules: 'Game rules',
    motorGoal: 'Motor goal',
    date: 'Date',
    time: 'Time',
    hour: 'Hour',
    place: 'Place',
    trainingPlace: 'Training place',
    numberOfBalls: 'Number of balls',
    ageCategory: 'Age category',
    workTime: 'Work time',
    numberOfPlayers: 'Number of players',
    numberOfGoals: 'Number of goals',
    numberOfMarkers: 'Number of markers',
    numberOfCones1: 'Number of cones (small)',
    numberOfCones2: 'Number of cones (big)',
    numberOfPoles: 'Number of poles',
    numberOfLadders: 'Number of ladders',
    balls: 'Balls',
    goals: 'Goals',
    markers: 'Markers',
    cones1: 'Cones',
    cones2: 'Cones',
    poles: 'Poles',
    ladders: 'Ladders',
    otherAccessories: 'Other accessories',
    trainingAccessories: 'Training accessories',
    areas: 'Areas',
    addittionalInformations: 'Addittional informations',
    modifications: 'Modifications',
    exercise: 'Exercise',
    rememberCoach: 'Remember Coach',
    descriptionMod: 'Description of the modification',
    addExercise: 'Ddd exercise',
    printPdf: 'Print pdf',
    save: 'Save',
    introductionName: 'Introductory part',
    introduction1Name: 'Introductory part I',
    introduction2Name: 'Introductory part II',
    mainName: 'Main part',
    main1Name: 'Main part I',
    main2Name: 'Main part II',
    finalName: 'Final part',
    notes: 'Notes',
    addPrivateExercise: 'Add an exercise from your base',
    addExerciseOnBooks: 'Add an exercise from your books',
    showInfoOutline: 'Show / hide information about the training in outline',
    showPartOutline: 'Show / hide trainings part in outline',
    showInfoExercise: 'Show / hide information about the training',
    showPartExercise: 'Show / hide trainings part',
    partName: 'Exercise',
    brand: 'Brand',
    zoomPlus: 'Zoom in',
    zoomMinus: 'Zoom out',
    previewMode: 'Preview outline',
    goToEdit: 'Go to edit',
    goToTab: 'Go to tab',
    goToPrivateOutline: 'Add outline to private materials',
    name: 'Name',
    qualityNotes: 'Qualitative remarks',
    settings: 'Auto outline settings',
    settings_description: 'Data here will automatically fill in each new outline to speed up the outline creation process.',
    settings_save: 'Save configuration',
    save_is_authenticated: 'The ability to save an outline is only available to users who have an account in the application. Create an account now!',
  },
  base: {
    your_base: 'Your material base',
    your_outlines: 'Your outlines',
    your_exercises: 'Your exercises',
    your_microcycles: 'Your microcycles',
    category_outlines: 'Private outline categories',
    category_exercises: 'Private exercise categories',
    category_microcycles: 'Categories private microcycles',
    space: 'Space',
    count: 'Count',
    private_outlines: 'Private outlines',
    empty_outlines: 'No outlines in database',
    private_exercises: 'Private exercises',
    empty_exercises: 'No exercises in database',
    search_exercise: 'Search exercise',
    exercise_name: 'Exercise name',
    new_category: 'New category',
    add_category: 'Add category',
    category: 'Category',
    color_category: 'Category color',
    color_group: 'Group color',
  },
  auth: {
    login_page: 'Login',
    email: 'Email',
    password: 'Password',
    login_link: 'Login',
    forgot_password_link: 'Forgot your password',
    register_link: 'Registration',
    register_page: 'Registration',
    register_page_description: 'The account is created automatically after entering the email address.',
    create_account: 'Create an account',
    register_created: 'The account was successfully created. The login password has been sent to your email. After logging in, you can change them in the account tab.',
    register_exist: 'You already have an account in the app. Log in now or forget password',
    forgot_page: 'Forgotten password',
    reset_password: 'Reset password',
    new_password: 'New password',
    confirm_password: 'Confirm new password',
    set_new_password: 'Set new password',
    reminder_password: 'Password reminder',
    reminder_password_description: 'An activation link has been sent to the provided email address, please click on it to set a new password for your account.',
    back_login: 'Back to login',
    forgot_success_page: 'Password has been set',
    forgot_success_page_description: 'From now on, you will use your new password to log in.',
  },
  club: {
    count: 'Count',
    settings: 'Settings',
    coaches: 'Coaches',
    date_of_birth: 'Birthday',
    coach: 'Coach',
    add_coach: 'Add coach',
    email: 'Email',
    email_coach: 'Coach`s email',
    additional_groups: 'Additional groups',
    group_main: 'Main group',
    empty_group: 'No groups',
    empty_coach_group: 'No coaches in the group',
    buy_space_coaches: 'Buy an extra coach space now',
    need_create_group: 'You need to create a group first',
    first_name: 'First name',
    last_name: 'Last name',
    your_first_name: 'Your first name',
    your_last_name: 'Your last name',
    groups: 'Groups',
    add_coach_to_club: 'Add coach to club',
    update_coach_details: 'Update coach details',
    information_board: 'Training schedule',
    information_board_page: 'Training schedule for',
    group: 'Group',
    group_link: 'Link to group',
    embedded_board: 'Embedded code',
    board_settings: 'Training schedule settings',
    board_settings_description: 'Data here will be automatically shown in the training schedule.',
    bank_account_number: 'Bank account number',
    description_to_the_account_number: 'Description to the account number',
    facebook: 'FB fanpage',
    instagram: 'Instagram',
    youtube: 'Youtube',
    save_settings: 'Save settings',
    start_range: 'Start range',
    end_range: 'End range',
    statistics: 'Statistics',
    see_statistics: 'See statistics',
    no_statistics: 'There are no statistics for this group.',
    change_group: 'Change group or date range',
    player: 'Player',
    frequency: 'Frequency',
    count_player_training: 'Number of players in training',
    training_attendance: 'Training attendance',
    has_a_telephone_number: 'Has a telephone number',
    count_player_dues: 'Number of dues paid',
    manager_attendance: 'Attendance management',
    list_reported_absences: 'Reported absences',
    list_completed_absences: 'Completed absences',
    add: 'Add',
    lack: 'Lack',
    reported_absence: 'Reported absence',
    calendar: 'Calendar',
    training: 'Training',
    private_outline: 'Private Outline',
    outline_link: 'Link to outline',
    view_attendance: 'View Attendance - Attendance',
    empty_trainings: 'No training',
    add_training: 'Add training',
    new_training: 'New training',
    microcycle: 'Microcycle',
    no_events_days: 'No events on this day',
    mesocycle: 'Mesocycle',
    area_technique: 'Technique / individual activities',
    area_motor: 'Motor area',
    area_mental: 'Mental area',
    area_knowledge: 'Knowledge area',
    your_groups: 'Your groups',
    name: 'Name',
    color_group: 'Group color',
    premium_amount: 'Premium amount',
    description: 'Description',
    save: 'Save',
    new_group: 'New group',
    create_new_group: 'Create a new group',
    empty_groups: 'No fields',
    pitches_list: 'Pitches list',
    pitch: 'The pitch',
    name_pitch: 'Pitch name',
    empyt_pitches: 'No pitches',
    new_pitch: 'New pitch',
    putch: 'Field',
    add_pitch: 'Add Pitch',
    street: 'Street',
    city: 'City',
    save_pitch: 'Save Pitch',
    dues_list: 'List of dues',
    empty_dues: 'No dues',
    date_of_payment: 'Date of payment',
    premium_payment_date: 'Premium payment date',
    new_dues: 'New dues',
    generate_dues: 'Generate premium',
    amount_dues: 'Contribution amount',
    players: 'Players',
    dues: 'Medium',
    save_dues: 'Duke',
    dues_for: 'Due for',
    players_list: 'Players list',
    empty_players_group: 'No players in group',
    phone_number: 'Phone number',
    notes: 'Notes',
    zip_code: 'Postal Code',
    add_player_group: 'Add player to group',
    new_player: 'New player',
    trainings: 'Trainings',
    place_training: 'Training location',
    start_time: 'Start time',
    end_time: 'End time',
    training_date: 'Training date',
    empty_trainings_group: 'No training in the group',
    subject: 'Subject',
    add_new_training: 'New New Training',
    advanced_settings: 'Advanced Settings',
    add_training_in_each: 'Add training in each',
    week: 'week',
    month: 'month',
    next_period: 'for the next (periods)',
    link: 'Link',
    lack_group: 'No group',
    new_attendance: 'New presence',
    attendance: 'Attendance',
    check_attendance: 'Check attendance',
    list_attendance: 'Attendance list',
    list_dues: 'Dues list',
    type_stats: 'A type of statistic', 
    save_attendance: 'Save attendance',
    planning_trainings: 'Training plans',
    empty_planning_trainings: 'No training plans',
    new_planning_training: 'New training plan',
    planning_training: 'Training plan',
    start_date_mesocycle: 'Mesocycle start date',
    end_date_mesoccle: 'Mesoccle end date',
    end_date_mesoccle_base_start: 'Mesocycle end date based on mesocycle start date',
    color: 'Color',
    create_new_planning_training: 'Create a new training plan',
    need_add_player_to_group: 'You need to add a player to group first',
    new_pzpn: 'Compliant with training certificate PZPN',
    status_player: 'Player status',
    status_active: 'Active',
    status_not_active: 'Inactive',
  },
  wallet: {
    wallet_page: 'Wallet',
    withdraw: 'Withdrawal',
    balance: 'Balance',
    total_amount_paid_out: 'Total amount paid out',
    count_payments: 'Number of payments',
    date_payment: 'Date of payment',
    withdraw: 'Withdrawal',
    status: 'Status',
    status_1: 'Waiting to add invoice',
    status_2: 'Correction needed',
    status_3: 'Processing',
    status_4: 'Payment completed',
    no_data: 'No date',
    history: 'History',
    count: 'Count',
    amount: 'Amount',
  },
  payment: {
    payment_page: 'Access to all outlines and exercises!',
    payment_page_description: 'By purchasing a subscription for a selected period of time, you get access to all publications on the Soccer Sessions platform. New additions and exercises will be available immediately.',
    choice_currency: 'Choose currency',
    pln_currency: 'polish zloty',
    choice_plan: 'Choose a plan',
    plan_1: 'Access for 1 month',
    plan_3: 'Access for 3 months',
    plan_6: 'Access for 6 months',
    plan_12: 'Access for 12 months',
    choice_company: 'Billing information (company only)',
    view_company_details: 'View company details',
    company_data: 'Company data',
    company: 'Name / company',
    street: 'Street',
    zip_code: 'Postal Code',
    city: 'City',
    country: 'Country',
    VAT: 'NIP number',
    save_company: 'Save company details',
    payment_warning: 'Your plan will automatically renew, but you can cancel your subscription at any time.',
    pay: 'Pay now',
    success_page: 'Payment accepted',
    success_page_description: 'From now on you can use paid materials in the application',
    failed_page: 'Payment failed',
    failed_page_description: 'The reason may be not enough money in the account or payment cancellation, you need to contact support (contact@soccer-sessions.com) or your bank for clarification.',
    retry_payment: 'Retry payment'
  },
  account: {
    publications: 'Publications',
    active: 'Active',
    during_subscription: 'during the subscription period',
    for_life: 'for life',
    view_publications: 'View purchased publications',
    none_purchased: 'No Purchases',
    publication: 'publication',
    list_publications: 'List of purchased publications',
    subscriptions: 'Subscriptions',
    active_until: 'Active until',
    no_active_subscriptions: 'No active subscriptions',
    view_orders: 'View Orders',
    list_subscriptions: 'List of ordered subscriptions',
    invoice_prepare: 'Invoice being prepared. It will be available for download within a few days.',
    only_subscriptions: 'Only subscription orders are listed here.',
    date: 'Date',
    amount: 'Amount',
    status: 'Status',
    status_1: 'Payment successfully processed',
    status_2: 'Error during payment',
    empty_orders: 'No orders',
    private_outlines: 'Private Outlines',
    training_planning: 'Training planning',
    crm: 'CRM',
    additions: 'Additions',
    coach_club: 'Club Coach',
    basic_information: 'Basic information',
    email: 'Email',
    email_placeholder: 'Your email',
    club: 'Club name',
    club_placeholder: 'Your club name',
    name: 'Name',
    name_placeholder: 'Your name',
    last_name: 'Your name',
    last_name_placeholder: 'Your name',
    phone_number: 'Phone',
    phone_number_placeholder: 'Phone number',
    birth: 'Date of birth',
    currency: 'Currency',
    update_data: 'Updates data',
    password_change: 'Password change',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm new password',
    change_password: 'Change password',
    subscription_cancellation: 'Unsubscribe',
    cancel_your_subscription: 'Cancel Subscriptions',
    deleting_accounts: 'Deleting account',
    deleting_accounts_description: 'It`s a pity that you want to delete your Konspekty Football account. Please send a message to contact@soccer-sessions.com with a request to delete your account. Support will close the account within 5 business days.',
    delete_account: 'Delete Account'
  },
  best_coach: {
    header_primary_1: 'The best trainers,',
    header_primary_2: 'are prepared for classes every day',
    section_primary_1: 'Invest in the development of your coaching career with a subscription to materials available on the Soccer Sessions platform!',
    section_primary_2: 'We have collected outlines and training exercises from the largest training brands in Poland in one place, providing you with a comprehensive collection of over 2,300 materials divided into categories. It`s not just content, it`s collaboration with industry leaders, also visible in our impressive portfolio of partners',
    buy_now: 'Buy now',
    buy_right_now: 'Buy access now',
    buy_right_now_2: 'Buy access now',
    see_more: 'Learn more',
    buy_publications: 'Buy access to publications',
    header_about_1: 'About us Soccer Sessions',
    header_about_2: 'Soccer Sessions',
    section_about_1: 'Join over 3,000 trainers who already use our publications on a daily basis. With Soccer Sessions, every coach, regardless of experience level, will find valuable tools to create ideal training units that will enable the full development of their players.',
    section_about_2: 'Soccer Sessions is also a partnership with Trainer`s Assistant, a project addressed to all coaches working with children and youth. Regardless of whether you train leprechauns, kids, eagles or youngsters, our platform is tailored to your needs. Here you will find proven training outlines and exercises that effectively support your classes. Join today and discover how Soccer Sessions can revolutionize your training!',
    section_about_3: 'Platform',
    section_about_4: 'Discover over 70 publications and an impressive 2,300 outlines and exercises, creating a comprehensive knowledge base for every football coach.',
    section_about_5: 'Database of private materials',
    section_about_6: 'Our application allows you to create your own outlines and exercises using the Private Outlines module. Thanks to compliance with the Polish Football Association Training Certificate, you can be sure that the created materials comply with the highest coaching standards. The simple and quick method of creating outlines gives you control over the content, and the ability to print or save them as a PDF file makes them ready for use in every class.',
    section_about_7: 'Community',
    section_about_8: 'Join over 3,000 users using our app. We are proud of cooperation with the largest partners in Poland and the active support of the Trainer Assistant group. Together we create a strong community that inspires and develops coaching skills.',
    section_about_9: 'Develop with us on the Soccer Sessions platform - your indispensable tool for improving football training!',
    quote: 'The extraordinary achievements of the players are the result not only of their own talent, but also of the excellent work of the coach. When a coach is thoroughly prepared for training, player development becomes inevitable.',
    header_product_1: 'Soccer Sessions Subscription',
    section_product_1: 'Join our dynamic community and discover a unique offer that will give you access to over 70 exclusive coaching publications with a total value of over PLN 3,500. This is a unique proposition on the market, enabling the use of over 2,300 outlines and exercises. As part of the subscription, you also gain the ability to create your own materials in the Private Outlines application, where you edit, print and even save them in PDF format.',
    section_product_2: 'Training partners include brands such as:',
    section_product_3: 'Our database covers all age groups: leprechaun, student, eagle, youngster, sneaker, junior junior, senior junior and senior. In addition, special categories such as small games, motor skills, intelligence development and futsal provide a full range of training for various needs. Thanks to intuitive filters on the website, you will quickly and easily find materials tailored to your group.',
    section_product_4: 'But that`s not all.',
    section_product_5: 'Stop bothering with closed PDFs! Our application offers a Private Outlines module, where trainers can freely use materials from the database, modify them, save, print and export to PDF format. Everything in accordance with PZPN training certification.',
    section_product_6: 'The subscription includes not only access to the current database, but also free access to new publications that we regularly add to Soccer Sessions. In 2023, we introduced as many as 39 new materials, which gives an average of 3 publications per month.',
    section_product_7: 'See how Soccer Sessions can revolutionize your training. Join today and enjoy comprehensive support for your coaching work!',
    header_advantage_1: 'The most important benefits of the Soccer Sessions database',
    section_advantage_1: 'Access to over 70 publications (over 2,300 outlines and exercises)',
    section_advantage_2: '24/7 access on any mobile device with the ability to create a mobile application on your phone`s desktop',
    section_advantage_3: 'Possibility to create your own outlines and exercises based on publications from the database',
    section_advantage_4: 'Unlimited access not only to existing but also new publications',
    section_advantage_5: 'Save money. The cost of an annual subscription is just over ... a euro a day',
    section_advantage_6: 'In the database, there are several publications that are only available through subscription. Moreover, they are evergreen, as they will regularly be supplemented with new outlines and exercises at specified intervals.',
    header_publications: 'Full list of publications:',
    section_publications: 'Another publications in preparation!',
    header_faq: 'Questions before purchasing?',
    section_faq_1: 'Will I receive a VAT invoice for the company?',
    section_faq_2: 'Yes, just provide your company details when purchasing or contact us after purchasing. We will place the VAT invoice in PDF form in a specially prepared view in the application, where it will be possible to download it to disk. If you need a proforma invoice, please write to us at',
    section_faq_3: 'How can I pay for my order?',
    section_faq_4: 'Payments are handled by the Stripe system, which allows you to pay by card.',
    section_faq_5: 'Is there a limit on viewing content?',
    section_faq_6: 'No. You can use the materials an unlimited number of times, at any time, on the device of your choice.',
    section_faq_7: 'What if I already have access to several publications?',
    section_faq_8: 'If you have previously purchased access to any publication, you still have access to it. The subscription includes access to all publications in the database. After you end your subscription or unsubscribe, you still have access to previously purchased individual publications.',
    section_faq_9: 'What about extending the subscription for another year?',
    section_faq_10: 'This happens automatically, based on the data provided when you first ordered the subscription, which you can cancel at any time.',
    section_faq_11: 'Does the platform offer technical support or help with access problems?',
    section_faq_12: 'Of course, in case of any technical problems, we will provide appropriate support. Our development team monitors application performance and regularly eliminates any errors.',
    section_faq_13: 'How often are new materials added to the platform?',
    section_faq_14: 'Our database of publications is constantly growing. In 2023, we added 39 publications, which means a new publication appeared every 10 days.',
    section_faq_15: 'If you have additional questions about the subscription, invoice or order processing, please feel free to write to the address',
    section_faq_16: 'We are ready to help!',
    header_app: 'See how the app works',
    what_is_sub: 'Subscription'
  },
  sms: {
    body: 'Text message',
    new_sms: 'New SMS',
    send_sms: 'Send SMS'
  },
  popover: {
    calendar: 'A comprehensive training calendar for the selected group. With the option to switch between months and days, you can add or edit trainings. You can track attendance and participation using specific features. Additionally, after adding and setting training plans for each group, their schedules will also be displayed on the calendar.',
    coaches: 'A list of coaches in the club who have access to training groups. They can add, edit, and remove players, trainings, fees, attendance, and training plans. Coaches can also be removed at any time.',
    information_board_page: 'A training schedule for a specific age group, which can be placed on a website or shared as a link. The schedule is public.',
    statistics: 'Comprehensive statistics for each training group over any period of time, showing player attendance at trainings or fees paid in a given month.',
    your_groups: 'A place to manage training groups in the club. You can assign a color to each group, which will then be displayed on the calendar. Practically every section in the CRM contains information about the currently active training group.',
    players: 'Managing players in a specific group. Each player can belong to several training groups and has a default main group. By editing a player, you can update their information and manage their attendance by reporting or completing a training session.',
    dues_list: 'A list of generated fees for a specific training group, indicating the payment deadline and which player has paid for a specific period.',
    pitches_list: 'A list of training pitches in the club, which can be selected when creating a training session and displayed in a public training schedule.',
    planning_trainings: 'Individual training plans for each group, displayed in the calendar for coaches to see what each group is currently working on.',
    trainings: 'A list of trainings for a specific group, including the date, start and end times, and location. Additionally, you can add a lesson plan. Trainings are displayed in the calendar and public schedule.',
    new_attendance: 'Checking the attendance list. The system indicates which player is inactive in the CRM or has reported absence from training.',
    new_sms: 'Possibility to send text messages for training groups from your phone. The system collects all telephone numbers of players in the group and prepares an SMS. The functionality only works on a telephone device.',
  }
};