export default {
  global: {
    all: 'Wszystkie',
    all2: 'Wszyscy',
    new: 'Nowość',
    diet: 'Dieta',
    sms: 'SMS',
    historySms: 'Historia SMS',
    match: 'Mecze',
    player_training: 'Trening w domu',
    announcement: 'Zapowiedź',
    offer_in_preparation: 'Oferta w przygotowaniu',
    coaching_notepad: 'Kajet trenerski',
    coaching_notepad_description: 'to możliwość zarządzania klubem.',
    coaching_notepad_publications: '📚 Publikacje',
    coaching_notepad_publications_description_1: 'Moduł „Publikacje” to wirtualna skarbnica wiedzy dla trenerów piłkarskich. Zawiera płatne materiały od największych marek trenerskich w Polsce, oferując dostęp do ponad 60 publikacji, co równa się ponad 2000 konspektów i ćwiczeń. Materiały te są dostępne dla każdej kategorii wiekowej, od najmłodszych graczy (U4) po piłkę seniorską.',
    coaching_notepad_publications_description_2: 'Bogactwo materiałów: W bazie „Publikacje” znajdują się materiały od renomowanych marek trenerskich, co zapewnia dostęp do najnowszych i najbardziej wartościowych treści.',
    coaching_notepad_publications_description_3: 'Dostosowanie do indywidualnych konspektów: Materiały z „Publikacji” mogą być wykorzystywane w tworzeniu prywatnych konspektów i zapisywane we własnej bazie, co pozwala na spersonalizowanie treningów zgodnie z potrzebami drużyny.',
    coaching_notepad_publications_description_4: 'Dla wszystkich kategorii wiekowych: Materiały dostępne w „Publikacjach” obejmują szeroki zakres kategorii wiekowych, co pozwala na znalezienie treści odpowiednich dla każdej grupy zawodników.',
    coaching_notepad_publications_description_5: 'Moduł „Publikacje” to narzędzie, które wzbogaca wiedzę trenera i dostarcza cenne materiały treningowe. To skarbnica wiedzy, która pomaga w tworzeniu skutecznych treningów i rozwijaniu zawodników na każdym poziomie wiekowym.',
    coaching_notepad_private_outlines: '🗂️ Prywatne konspekty',
    coaching_notepad_private_outlines_description_1: 'Funkcja „Prywatne Konspekty” to centralna baza materiałów i ćwiczeń, stworzona za pomocą modułu, która pozwala na tworzenie, organizację i dostęp do wartościowych materiałów treningowych. To narzędzie umożliwia kategoryzowanie materiałów i tworzenie spersonalizowanych konspektów i ćwiczeń.',
    coaching_notepad_private_outlines_description_2: 'Indywidualna baza konspektów: Twórz i przechowuj konspekty oraz ćwiczenia w jednym miejscu, dostosowane do Twoich potrzeb.',
    coaching_notepad_private_outlines_description_3: 'Kategoryzacja materiałów: Organizuj materiały poprzez kategoryzowanie ich według tematów, poziomu trudności lub innych kryteriów.',
    coaching_notepad_private_outlines_description_4: 'Drukowanie i zapisywanie do PDF: Materiały mogą być drukowane lub zapisywane do formatu PDF, co pozwala na wygodne korzystanie z nich podczas treningów.',
    coaching_notepad_private_outlines_description_5: 'Standardowy podział na elementy treningu: Konspekty i ćwiczenia są przygotowane zgodnie z standardem, co ułatwia tworzenie i dostosowywanie treningów.',
    coaching_notepad_private_outlines_description_6: 'Funkcja „Prywatne Konspekty” to narzędzie, które pomaga trenerom w tworzeniu i organizacji materiałów treningowych. Daje pełną kontrolę nad zasobami treningowymi, co umożliwia dostosowywanie treningów do potrzeb drużyny i osiąganie lepszych wyników na boisku.',
    coaching_notepad_calendar: '📆 Kalendarz',
    coaching_notepad_calendar_description_1: 'Nasz moduł kalendarza to serce Twojego klubu piłkarskiego. To tu znajdziesz wszystkie niezbędne informacje dla klubu i grup związanych z organizacją zajęć. To więcej niż zwykły kalendarz - to narzędzie do profesjonalnego zarządzania treningami i efektywnego planowania.',
    coaching_notepad_calendar_description_2: 'Pełny miesiąc w jednym widoku: Przeglądaj cały miesiąc dla danej grupy na jednym ekranie. To idealne narzędzie do bieżącego monitorowania planu treningów i innych wydarzeń klubowych.',
    coaching_notepad_calendar_description_3: 'Szybki dostęp do treningów: Nie tracisz czasu na przeszukiwanie starych notatek ani długotrwałe planowanie. Kalendarz daje Ci szybki dostęp do treningów - kliknij i już jesteś gotów.',
    coaching_notepad_calendar_description_4: 'Sprawdzaj obecność: Śledź obecność zawodników w prosty i przejrzysty sposób. Oszczędź czas na liczeniu obecności ręcznie.',
    coaching_notepad_calendar_description_5: 'Planuj treningi: Twórz i planuj treningi z łatwością. Dodawaj ćwiczenia, określ cele i zapisuj notatki, aby każdy trening był jak najlepiej dostosowany do potrzeb Twojej drużyny.',
    coaching_notepad_calendar_description_6: 'Dzięki modułowi kalendarza w naszej aplikacji, zarządzanie treningami i organizacją klubową jest teraz prostsze i bardziej efektywne niż kiedykolwiek wcześniej. Zyskaj kontrolę nad harmonogramem i skup się na doskonaleniu swojej drużyny.',
    coaching_notepad_information_board: '🌐 Tablica informacyjna',
    coaching_notepad_information_board_description_1: 'Funkcja „Tablica Informacyjna” to narzędzie, które przynosi przejrzystość i komfort w śledzeniu planu treningów. To nie tylko kalendarz, to okno do naszego klubu piłkarskiego, dostępne na dowolnej stronie www w postaci iframe lub linka.',
    coaching_notepad_information_board_description_2: 'Kalendarz treningów na Twojej stronie: Teraz możesz umieścić nasz kalendarz treningów na swojej stronie internetowej. To znaczy, że każdy, kto jest związany z klubem, może na bieżąco śledzić harmonogram zajęć bez konieczności wchodzenia do aplikacji.',
    coaching_notepad_information_board_description_3: 'Przejrzystość w jednym spojrzeniu: Dzięki „Tablicy Informacyjnej” każdy uczestnik zajęć widzi, jakie treningi odbywają się i kiedy. To świetne narzędzie do planowania swojego czasu i zapewnienia, że nikt nie przegapi żadnego treningu.',
    coaching_notepad_information_board_description_4: 'Łatwe dostosowanie i udostępnianie: Funkcja ta daje Ci kontrolę nad wyglądem kalendarza na Twojej stronie internetowej. Dostosuj go do swoich potrzeb i udostępnij wszystkim zainteresowanym.',
    coaching_notepad_information_board_description_5: 'Teraz nie musisz się martwić, że ktoś przeoczy trening. Funkcja „Tablica Informacyjna” pozwala na śledzenie treningów w czasie rzeczywistym, co sprawia, że zarządzanie klubem staje się jeszcze bardziej przyjazne i efektywne. Daj swoim członkom łatwy dostęp do informacji o treningach, dzięki tej wygodnej funkcji.',
    coaching_notepad_group: '🧑‍🤝‍🧑 Grupy',
    coaching_notepad_group_description_1: 'Funkcja „Grupy” to klucz do zorganizowania i personalizacji Twoich treningów w naszej aplikacji. To narzędzie pozwala Ci stworzyć grupy treningowe z ich własnymi oznaczeniami, które będą identyfikować daną grupę w różnych modułach aplikacji.',
    coaching_notepad_group_description_2: 'Tworzenie grup treningowych: Teraz możesz grupować swoich zawodników w wygodne jednostki treningowe. Tworzenie grup jest szybkie i intuicyjne.',
    coaching_notepad_group_description_3: 'Personalizacja kolorów: Każda grupa może mieć swój własny główny kolor identyfikacyjny. To nie tylko ułatwia rozpoznanie, ale także nadaje aplikacji bardziej spersonalizowanego charakteru.',
    coaching_notepad_group_description_4: 'Szybkie wyszukiwanie: Dzięki grupom znajdziesz potrzebne informacje szybciej. Wyszukaj po kolorze grupy i łatwo znajdź odpowiednie dane.',
    coaching_notepad_group_description_5: 'Z funkcją „Grupy”, zarządzanie wieloma grupami treningowymi staje się bardziej przejrzyste i efektywne. Każda grupa ma swój własny kolor, co pomaga w szybkim rozpoznawaniu i zidentyfikowaniu odpowiednich danych. To narzędzie, które sprawia, że praca z aplikacją jest jeszcze bardziej dostosowana do Twoich potrzeb.',
    coaching_notepad_players: '👤 Zawodnicy',
    coaching_notepad_players_description_1: 'Funkcja „Zawodnicy” to serce Twojego klubu piłkarskiego w jednym miejscu. To tutaj znajdziesz kompletną listę zawodników w danej grupie wiekowej, a także wszystkie niezbędne informacje, które są kluczowe w zarządzaniu klubem.',
    coaching_notepad_players_description_2: 'Pełna lista zawodników: Dzięki funkcji „Zawodnicy” masz dostęp do pełnej listy zawodników w danej grupie wiekowej. To idealne narzędzie do monitorowania składu drużyny.',
    coaching_notepad_players_description_3: 'Informacje CRM: Wszystkie niezbędne informacje o zawodnikach, takie jak dane kontaktowe i wiele innych, gromadzone są w jednym miejscu. Dzięki temu zarządzanie nimi jest niezwykle efektywne.',
    coaching_notepad_players_description_4: 'Szybkie wyszukiwanie: Dzięki funkcji wyszukiwania znajdziesz potrzebne informacje szybciej. Wyszukuj po nazwisku lub innym kryterium.',
    coaching_notepad_players_description_5: 'Funkcja „Zawodnicy” daje Ci kontrolę nad danymi i informacjami dotyczącymi Twoich zawodników. To narzędzie, które ułatwia zarządzanie drużyną, śledzenie postępów zawodników i dbanie o ich potrzeby. Dzięki „Zawodnicy” masz pełny przegląd swojej drużyny w jednym miejscu.',
    coaching_notepad_membership_dues: '💵 Składki',
    coaching_notepad_membership_dues_description_1: 'Moduł „Składki” to kluczowe narzędzie do zarządzania płatnościami za zajęcia w Twoim klubie piłkarskim. To tutaj kontrolujesz i generujesz plany płatności, dbasz o regularne wpłaty i zachowujesz przejrzystość w finansach klubu.',
    coaching_notepad_membership_dues_description_2: 'Generowanie grafików płatności: Twórz harmonogram płatności, który jest zgodny z Twoim klubowym kalendarzem. Bez trudu generuj grafiki płatności na przykład co miesiąc.',
    coaching_notepad_membership_dues_description_3: 'Zaznaczanie dokonanych płatności: Dzięki modułowi „Składki” jesteś na bieżąco z płatnościami. Zaznaczaj płatności, gdy są dokonane, aby mieć pełny przegląd nad finansami klubu.',
    coaching_notepad_membership_dues_description_4: 'Przejrzystość w finansach: Zyskaj pełną kontrolę nad finansami klubu. Monitoruj status płatności, generuj raporty i zadbaj o to, żeby wszystkie opłaty były uregulowane.',
    coaching_notepad_membership_dues_description_5: 'Moduł „Składki” ułatwia zarządzanie finansami klubu piłkarskiego. Dzięki niemu masz kontrolę nad płatnościami, co pozwala skupić się na rozwijaniu drużyny i doskonaleniu treningów, wiedząc, że finanse są pod kontrolą.',
    coaching_notepad_training_sessions: '🕒 Treningi',
    coaching_notepad_training_sessions_description_1: 'Moduł „Treningi” to centralne miejsce dla Twoich zajęć piłkarskich, gdzie możesz tworzyć i zarządzać każdym aspektem treningu. To narzędzie pozwala na pełne planowanie, organizację i śledzenie treningów w Twoim klubie piłkarskim.',
    coaching_notepad_training_sessions_description_2: 'Miejsce i boisko: Dodawaj informacje o miejscu treningu, w tym szczegóły dotyczące boiska. Dzięki temu wszyscy uczestnicy wiedzą, gdzie odbędzie się trening.',
    coaching_notepad_training_sessions_description_3: 'Godziny rozpoczęcia i zakończenia: Określ dokładny czas rozpoczęcia i zakończenia treningu. To pozwala na dokładne zaplanowanie harmonogramu.',
    coaching_notepad_training_sessions_description_4: 'Pełny opis: Dodawaj opis treningu, aby zawierał wszystkie niezbędne informacje dotyczące planu treningowego, celów i szczegółów zajęć.',
    coaching_notepad_training_sessions_description_5: 'Podpięcie konspektu treningowego: Moduł „Treningi” pozwala na bezproblemowe dołączanie konspektów treningowych, co pomaga w utrzymaniu spójności i dostępie do materiałów treningowych.',
    coaching_notepad_training_sessions_description_6: 'W Kalendarzu grupy treningowej: Wszystkie informacje o treningach są dostępne w kalendarzu danej grupy treningowej, co ułatwia uczestnikom dostęp do planu zajęć.',
    coaching_notepad_training_sessions_description_7: 'Moduł „Treningi” to kompleksowe narzędzie do zarządzania treningami, które ułatwia tworzenie, organizację i udostępnianie planów treningowych. Dzięki niemu masz pełną kontrolę nad każdym aspektem treningu, co pomaga w doskonaleniu umiejętności i osiąganiu celów w Twoim klubie piłkarskim.',
    coaching_notepad_training_planning: '🎯 Planowanie treningu',
    coaching_notepad_training_planning_description_1: 'Moduł „Planowanie Treningu” to kluczowe narzędzie do osiągania celów treningowych na każdym mikrocyklu treningowym. To tutaj definiujesz cele i śledzisz postępy dla każdej grupy treningowej, a wszystko jest dostępne w kalendarzu, dzięki czemu na bieżąco wiesz, na jakim etapie jest dana grupa treningowa.',
    coaching_notepad_training_planning_description_2: 'Cele na mikrocykl: W module „Planowanie Treningu” określasz cele na każdy mikrocykl treningowy. Dzięki temu masz jasne wytyczne dotyczące tego, co chcesz osiągnąć w danym okresie.',
    coaching_notepad_training_planning_description_3: 'Indywidualne cele: Możesz dostosować cele samodzielnie dla każdej grupy treningowej. To oznacza, że treningi są dostosowane do potrzeb każdej drużyny.',
    coaching_notepad_training_planning_description_4: 'Kalendarz postępów: Wszystkie cele i postępy są widoczne w kalendarzu. Dzięki temu można na bieżąco śledzić, jak dana grupa treningowa realizuje swoje cele.',
    coaching_notepad_training_planning_description_5: 'Moduł „Planowanie Treningu” pomaga Ci w precyzyjnym zarządzaniu celami treningowymi i śledzeniu postępów. To narzędzie, które pozwala na indywidualne dostosowanie treningów do potrzeb każdej grupy, co przekłada się na osiągnięcie sukcesu na boisku.',
    coaching_notepad_fields: '🏟️ Boiska',
    coaching_notepad_fields_description_1: 'Moduł „Boiska” to narzędzie, które daje Ci pełną kontrolę nad lokalizacją zajęć piłkarskich. To tutaj tworzysz boiska z wszystkimi parametrami związanymi z lokalizacją, co pozwala na dokładne śledzenie, gdzie odbywają się zajęcia.',
    coaching_notepad_fields_description_2: 'Tworzenie boisk: Dzięki modułowi „Boiska” możesz tworzyć boiska, określając ich lokalizację, nazwę i inne parametry. To gwarantuje dokładne odwzorowanie miejsc, w których odbywają się treningi.',
    coaching_notepad_fields_description_3: 'Pełne informacje o lokalizacji: Dodawaj szczegółowe informacje o boiskach, takie jak adres, rodzaj nawierzchni, dostępność szatni i więcej. To pomaga w lepszym przygotowaniu do treningów.',
    coaching_notepad_fields_description_4: 'Kalendarz z lokalizacjami: Wszystkie boiska są widoczne w kalendarzu, co ułatwia uczestnikom śledzenie, gdzie odbywają się zajęcia.',
    coaching_notepad_fields_description_5: 'Moduł „Boiska” pozwala na pełną kontrolę nad lokalizacją treningów, co sprawia, że zarządzanie i śledzenie miejsc odbywania się zajęć jest bardziej przejrzyste i efektywne. To narzędzie, które pomaga w pełnym wykorzystaniu dostępnych lokalizacji i zapewnia dokładność w organizacji treningów.',
    coaching_notepad_statistics: '📈 Statystyki',
    coaching_notepad_statistics_description_1: 'Moduł „Statystyki” to potężne narzędzie do analizy i śledzenia wyników w Twoim klubie piłkarskim. Pozwala na podgląd statystyk dla danej grupy w określonym okresie czasu, co umożliwia kontrolę frekwencji na treningach oraz wiele innych aspektów.',
    coaching_notepad_statistics_description_2: 'Analiza wyników: Moduł „Statystyki” pozwala na analizę wyników, w tym frekwencji na treningach, wyników meczów i innych istotnych wskaźników.',
    coaching_notepad_statistics_description_3: 'Określenie okresu czasu: Możesz dostosować okres czasu, w którym chcesz przeprowadzić analizę statystyk. To umożliwia zarówno krótkoterminową analizę, jak i śledzenie długofalowych trendów.',
    coaching_notepad_statistics_description_4: 'Wizualizacja wyników: Statystyki są prezentowane w czytelny sposób za pomocą wykresów i grafik, co ułatwia zrozumienie i interpretację wyników.',
    coaching_notepad_statistics_description_5: 'Moduł „Statystyki” to narzędzie, które pomaga w lepszym zrozumieniu i śledzeniu postępów w klubie piłkarskim. Dzięki niemu masz dostęp do cennych danych, które pomagają w podejmowaniu informowanych decyzji i doskonaleniu funkcjonowania klubu.',
    coaching_notepad_role: '🤝 Rola trener w klubie',
    coaching_notepad_role_description_1: 'W roli trenera w naszej aplikacji piłkarskiej odgrywasz istotną rolę w procesie tworzenia i dostarczania wartościowego treningu dla zawodników. Twoje zadania i uprawnienia są dostosowane, aby efektywnie zarządzać treningami i współpracować z klubem.',
    coaching_notepad_role_description_2: 'Zarządzanie grupami: Trener ma dostęp tylko do przypisanych grup, co zapewnia skoncentrowane zarządzanie i pracę z konkretną drużyną.',
    coaching_notepad_role_description_3: 'Dostęp do treningów: Trener ma możliwość przeglądania i edycji treningów, które zostały mu przydzielone przez właściciela klubu, co umożliwia dostosowanie treningów do potrzeb drużyny.',
    coaching_notepad_role_description_4: 'Współpraca z klubem: Trener współpracuje z klubem, dzieląc się swoimi umiejętnościami i wiedzą w dziedzinie piłki nożnej. Wspiera rozwój zawodników i osiąganie celów klubu.',
    coaching_notepad_role_description_5: 'Dostęp do zasobów: Trener ma dostęp do publikacji i prywatnych konspektów, co umożliwia korzystanie z wartościowych materiałów szkoleniowych w procesie treningowym.',
    coaching_notepad_role_description_6: 'Rola trenera to kluczowy element współpracy z klubem i uczestniczenie w tworzeniu skutecznych treningów. Dzięki dostosowanym uprawnieniom, trener może skoncentrować się na rozwijaniu umiejętności zawodników i przyczyniać się do sukcesu drużyny.',
    coaching_notepad_sms: '📲 SMS',
    coaching_notepad_sms_description_1: 'Moduł „SMS” Moduł „SMS” to narzędzie, które umożliwia szybkie i efektywne komunikowanie się z zawodnikami. Dzięki niemu masz pełną kontrolę nad wyborem grup treningowych i wysyłaniem wiadomości SMS bezpośrednio do wybranych uczestników, wykorzystując natywną funkcjonalność SMS w swoim telefonie.',
    coaching_notepad_sms_description_2: 'Wybór grupy treningowej: Moduł „SMS” pozwala na łatwe wybieranie grup treningowych, do których chcesz wysłać wiadomość. Możesz wybrać całą grupę lub poszczególnych zawodników, co daje elastyczność w komunikacji.',
    coaching_notepad_sms_description_3: 'Wysyłanie wiadomości: Korzystając z modułu „SMS”, możesz szybko wysłać wiadomość SMS bezpośrednio z telefonu. To idealne rozwiązanie do przekazywania pilnych informacji, takich jak zmiany w harmonogramie, przypomnienia o treningach czy inne ważne komunikaty.',
    coaching_notepad_sms_description_4: 'Personalizacja wiadomości: Dodawaj indywidualne informacje do wiadomości, aby były bardziej spersonalizowane i skierowane do konkretnych potrzeb odbiorców. To pomaga w utrzymaniu zaangażowania i lepszym zrozumieniu przekazywanych treści.',
    coaching_notepad_sms_description_5: 'Pełna kontrola nad komunikacją: Moduł „SMS” daje Ci pełną kontrolę nad tym, kto otrzymuje wiadomości, co pozwala na efektywne zarządzanie komunikacją z zawodnikami. Dzięki temu masz pewność, że ważne informacje dotrą do odpowiednich osób na czas.',
    coaching_notepad_sms_description_6: 'Moduł „SMS” to nieocenione narzędzie do szybkiej i efektywnej komunikacji z zawodnikami, które zapewnia, że wszystkie ważne informacje są przekazywane w odpowiednim czasie. To sposób na utrzymanie płynnej komunikacji i organizacji, dzięki wykorzystaniu natywnych funkcji SMS w Twoim telefonie.',
    publication_zone: 'Strefa publikacji',
    app_zone: 'Strefa aplikacji',
    club_zone: 'Strefa klubu',
    coach_zone: 'Strefa trenera',
    player_zone: 'Strefa zawodnika',
    partner_zone: 'Strefa partnera',
    information_zone: 'Strefa informacji',
    privacy_policy: 'Polityka prywatności',
    regulations: 'Regulamin',
    faq: 'FAQ',
    faq_page: 'Często zadawne pytania',
    faq_private_outlines: 'Przewodnik po prywatnych konspektach',
    faq_account: 'Przewodnik po stworzonym końcie w aplikacji',
    faq_publications: 'Przewodnik po dostępie do publikacji',
    faq_stripe: 'Przewodnik po kupnie subskrypcji',
    stripe_1: 'Proces zakupu subskrypcji odbywa się na specjalnie przygotowanej stronie przez bramkę płatności Stripe, na której dokonujemy zakupu poprzez podanie adresu email i danych z karty płatniczej.',
    stripe_2: 'Po zakończeniu płatności zostajemy przekierowani na widok ze statusem płatności. Otrzymujemy email z informacją o przyznanym dostępie do bazy konspektów',
    stripe_3: 'Każda publikacja w subskrypcji zawiera etykiętę pełna wersja.',
    stripe_4: 'W bazie jest ponad 1500 konspektów i ćwiczeń.',
    faq_pwa: 'Przewodnik po instalacji aplikacji na pulpicie',
    pwa_0: 'Korzystaj z Soccer Sessions jak z natywnej aplikacji! Treść: Czy wiedziałeś, że Soccer Sessions jest dostępny jako PWA (Progressive Web Application)? Oznacza to, że możesz korzystać z naszej aplikacji bezpośrednio na swoim urządzeniu mobilnym, bez konieczności pobierania jej z Google Play lub App Store. Aby skorzystać z tej funkcji, po prostu otwórz naszą stronę na swoim smartfonie lub tablecie, a następnie dodaj ją do ekranu głównego. Poniżej krótka instrukcja jak to zrobić.',
    pwa_1: 'Po wejściu na stronę www w przeglądarce Google Chrome jest możliwość zainstalowania Soccer Sessions jako natywnej aplikacji na pulpicie telefonu lub komputera.',
    pwa_2: 'Komputer:',
    pwa_3: '1. W prawym górnym rogu przeglądarki klikamy w 3 kropki (dostosowywanie i kontrolowanie Google Chrome), a następnie z rozwiniętej listy wybieramy Zainstaluj Aplikację Soccer Sessions. Można również wybrać ikonkę instalacji z paska url po prawej stronie.',
    pwa_4: '2. Po wybraniu pojawi się komunikat czy chcesz zainstalować aplikację.',
    pwa_5: '3. Po instalacji aplikacja pojawi się na pulpicie i w pasku narzędzi.',
    pwa_6: 'Telefon:',
    pwa_7: '1. W prawym górnym rogu przeglądarki klikamy w 3 kropki, a następnie z rozwiniętej listy wybieramy Dodaj do ekranu głównego.',
    pwa_8: '2. Po wybraniu pojawi się komunikat czy dodać aplikację do ekranu głównego.',
    pwa_9: '3. Następie uruchomi się proces instalacji aplikacji. Po zakończeniu będzie informacja, że można uruchomić aplikację, a ikonka pojawi się na pulpicie telefonu.',
    pwa_10: '4. Od teraz Soccer Sessions będzie działać jak natywna aplikacja ze sklepu Google Play lub App Store.',
    faq_partner: 'FAQ partner',
    partner: 'Partner',
    player: 'Zawodnik',
    coach: 'Trener',
    club: 'Klub',
    presense: 'Obecności',
    dues: 'Składki',
    about: 'O nas',
    block_generator: 'Generator bloczków',
    create_a_tournament: 'Stwórz turniej',
    create_an_outline: 'Stwórz konspekt',
    tactical_board: 'Tablica taktyczna',
    account: 'Konto',
    your_base: 'Twoja baza',
    coaching_magazines: 'Magazyny trenerskie',
    library: 'Biblioteka',
    coach_jose: 'Trener José',
    coach_jose_title: 'Jestem trener José',
    coach_jose_write: 'Napisz coś trenerze...',
    coach_jose_description_1: 'Aktualnie jestem na stażu w najlepszych akademiach na świecie i uczę się, aby po powrocie być Twoim asystentem.',
    coach_jose_description_2: 'Będę mógł stworzyć za Ciebie konspekt dla każdej kategorii wiekowej, mikrocykl treningowy, a ponadto zaplanować pracę z grupą i kalendarzem w aplikacji KPN.',
    coach_jose_description_3: 'Potrzebuję teraz trochę czasu, do usłyszenia za jakiś czas.',
    coach_jose_init_full_1: 'Cześć, Jestem Trener José i chcę być Twoim osobistym asystentem.',
    coach_jose_init_full_2: 'Napisz mi jaki konspekt, ćwiczenie potrzebujesz a przygotuję Ci materiał dla Ciebie.',
    // coach_jose_init_full_3: 'Nazwa lub opis ćwiczenia - kategoria wiekowa - rodzaj materiału',
    // coach_jose_init_full_4: 'Przykład: Gra 1x1 - skrzat - konspekt lub Gra 1x1 - U6 - ćwiczenie',
    coach_jose_select_plan: 'Limit darmowych zapytań został wyczerpany, aby dalej móc korzystać z pełnej wersji chatu, musisz kupić dostęp',
    coach_jose_select_plan_buy: 'Kup subkrypcję na dowolny okres czasu',
    coach_jose_empty_response: 'Nie znalazłem żadnego konspektu ani ćwiczenia pasującego do opisu, który został przesłany. Spróbuj jeszcze raz!',
    coach_jose_must_login_or_register: 'Chat jest dostępny tylko dla użytkowników posiadających konto w aplikacji, więc zalogouj się lub stwórz konto',
    coach_jose_chat_history: 'Historia chatu',
    coach_jose_empty_chat_history: 'Brak w bazie zapytań do czatu',
    coach_jose_response_subscription_outline: 'Zobacz konspekt',
    coach_jose_response_subscription_exercise: 'Zobacz ćwiczenie',
    coach_jose_response_user_outline: 'Znaleziony konspekt, ale nie można zobaczyć, ponieważ nie posiadasz aktywnej subskrypcji',
    coach_jose_response_user_exercise: 'Znalezione ćwiczenie, ale nie można zobaczyć, ponieważ nie posiadasz aktywnej subskrypcji',
    favorite: 'Ulubione',
    favorite_description_1: 'Aktualnie brak ulubionych konspektów lub ćwiczeń.',
    favorite_description_2: 'Dodaj konspekt lub ćwiczenie do ulubionych poprzez kliknięcie w ikonkę.',
    publications: 'Publikacje',
    crm: 'Crm',
    settings: 'Ustawienia',
    statistics: 'Statystyka',
    calendar: 'Kalendarz',
    choose_a_role: 'Wybierz rolę',
    pitches: 'Boiska',
    login: 'Zaloguj',
    logout: 'Wyloguj',
    groups: 'Grupy',
    wallet: 'Portfel',
    players: 'Zawodnicy',
    trainings: 'Treningi',
    training_planning: 'Planowanie treningu',
    trainers: 'Trenerzy',
    information_board: 'Terminarz treningów',
    home: 'Strona główna',
    site_under_construction: 'Site under construction',
    this_functionality_will_be_available_soon: 'This functionality will be available soon ',
    contact: 'Kontakt',
    recommendations: 'Rekomendacje',
    recommendation_futsal: 'Futsal Kolobrzeg',
    recommendation_futsal_description: 'Materiały znajdujące się w bazie pozwalają naszym trenerom rozwijać się i być zawsze przygotowanym do każdego szkolenia. Moduł listy obecności wprowadził rewolucję w klubie, ponieważ teraz pod koniec sezonu jesteśmy w stanie wyciągnąć statystyki i przeanalizować je wraz z zarysami, które były na zajęciach, aby w przyszłym sezonie być jeszcze lepszym zespołem.',
    recommendation_futsal_link: 'Zobacz projekt Futsal Kołobrzeg',
    recommendation_futbolovve: 'Futbolovve trenerzy',
    recommendation_futbolovve_description: 'Organizacja i porządek to klucz w prowadzeniu stowarzyszenia jak nasze, a aplikacja konspekty piłka nożna jest stworzona do tego by te aspekty wnieść na wyższy poziom i z pewnością ją polecamy! Kontrola składek, zarządzanie kalendarzem i planowanie treningów jest bardzo proste i intuicyjne. Ta aplikacja jest też bardzo pomocna dla trenerów i osób zarządzających. W swojej bazie ma wiele ciekawych konspektów i pomimo wypracowanych swoich własnych metod opartych na swoich doświadczeniach nie boimy zaglądać w nowe materiały szkoleniowe.',
    recommendation_futbolovve_link: 'Zobacz projekt Futbolovve',
    recommendation_pzpn: 'Miłosz Stępiński – główny trener reprezentacji Polski kobiet i wychowawca trenerski Polskiego Związku Piłki Nożnej',
    recommendation_pzpn_description: 'Z dużą radością i zaciekawieniem sięgnąłem po opracowanie Panów Klimczaka i Bączyka dotyczące pracy ze `skrzatami`. Na rodzimym rynku wydawniczym jest co prawda kilka opracowań PZPN traktujących o szkoleniu na tym etapie, ale w żadnym nie zawarto tak wielu, dobrze opracowanych i przede wszystkim sprawdzonych w praktyce gotowych konspektów treningowych. >Każdy, kto pracuje z 5 i 6 latkami wie, jak trudna jest to grupa do prowadzenia, przede wszystkim pod względem organizacyjnym. Zawodnicy i zawodniczki odwdzięczają się szkoleniowcom swoim entuzjazmem i zaangażowaniem w zabawy i gry, ale każde zajęcia muszą być dokładnie przygotowane, przemyślane i co najważniejsze – dostosowane do wieku ćwiczących. Opracowanie Konspekty dla skrzatów 5-6 lat spełnia te wszystkie wymogi i na pewno będzie pomocnym narzędziem dla wszystkich pracujących z dziećmi na pierwszym etapie szkolenia. Moi studenci specjalizacji piłka nożna Uniwersytetu Szczecińskiego mają ten podręcznik jako jeden z obowiązkowych. Gorąco polecam.',
    recommendation_pzpn_link: 'Zobacz publikacje',
    partners: 'Partnerzy',
    authors: 'Autorzy',
    authors_description_1: 'to młody team który swoją pasję i jednocześnie ścieżkę zawodową przekuł w pomysł na biznes. Na swoim koncie mają już kilka znakomitych publikacji, które zostały dostrzeżone przez trenerów dziecięcej piłki nożnej z całej Polski, mogą pochwalić się także współpracą z gigantem jakim jest Asystent Trenera.',
    authors_description_2: 'Oprócz drukowanych książek z merytorycznie opracowanymi konspektami oraz ich elektronicznych wersji, marka wypuściła także darmowe aplikacje, które służą usprawnieniu pracy trenerskiej. Wśród nich znajdziemy: generator bloczków trenerskich z możliwością dodania swojego logo, tablicę taktyczną do szybkiego zaprezentowania schematu rozegrania piłki, tworzenie konspektu treningowego zgodnego z PZPN oraz kreatora tworzenia szybkiego turnieju dla kilku drużyn grających na kilku boiskach.',
    authors_description_3: 'Jeżeli jesteś trenerem dziecięcej piłki nożnej to absolutnie powinieneś znać i śledzić markę Soccer Sessions, bowiem odpowiada ona na aktualne potrzeby trenerskie, tworzy innowacyjne aplikacje i wspiera trenerów merytoryczną wiedzą, często nieodpłatnie.  ',
    soccer_sessions_about_1: 'powstał przede wszystkim dla osób, które na co dzień prowadzą treningi piłkarskie. Wiedząc jak bardzo ważnym i istotnym elementem jest odpowiednie przygotowanie trenera do zajęć sportowych, aby te były efektywne i atrakcyjne, chcemy podzielić się naszym doświadczeniem.',
    soccer_sessions_about_2: 'Poza gotowymi przykładami konspektów, które są ułożone w logiczny i uporządkowany sposób z przystosowaniem dla odpowiedniej kategorii wiekowej, chcemy przede wszystkim zainspirować Ciebie, trenera piłki nożnej.',
    soccer_sessions_about_3: 'W naszych publikacjach znajdziesz liczne ćwiczenia i modyfikacje, które mogą posłużyć jako materiał na najbliższy trening, zaczynając od kategorii skrzat, żak oraz orlik. Jednocześnie chcemy, aby wiedza, którą przekazujemy była dla Ciebie jak najłatwiej dostępna, dlatego aby sprostać Twoim oczekiwaniom nasze publikacje dostępne są zarówno w wersji papierowej, cyfrowej PDF oraz w wersji mobilnej, jako aplikacja na smartphone.',
    soccer_sessions_about_4: 'Dodatkowo na naszej stronie www.soccer-sessions.com oferujmy dostęp do darmowej wiedzy, biblioteki trenera oraz aplikacji mające na celu ułatwić pracę trenera.',
    file_name: 'Nazwa pliku',
    here: 'tutaj',
    write_to_us: 'napisz do nas',
    required: 'Wymagane',
    not_found: 'Nie ma takiej strony',
    not_found_description: 'Wygląda na to, że się zgubiłeś',
    change_role: 'Brak dostępu dla wybranej roli',
    change_role_description: 'Aby dostać się w to miejsce musisz zmienić rolę w aplikacji',
    change_role_link: 'Zmień rolę',
    no_access: 'Nie masz dostępu',
    no_access_description: 'Aby dostać się w to miejsce musisz posiadać konto w aplikacji',
    construction: 'Strona w budowie',
    construction_description: 'Funckjonalność będzie dostępna za jakiś czas',
    or: 'lub',
    or_contact: 'lub do kontaktu',
    no_access_books_1: 'Pełna wersja aplikacji ze wszystkimi konspektami jest dostępna w naszym sklepie w formie pojedyńczego zakupu lub kupienia dostępu w formie subskrypcji na określony czas.',
    no_access_books_2: 'Zapraszamy również do odwiedzenia naszej strony www',
    buy_subscriptions: 'Kup subskrypcję',
    buy_single: 'Kup teraz pojedyńczy dostęp',
    empty_list_books: 'Brak publikacji spełniających kryteria wyszukiwania',
    more_buy_single : 'To jest wersja demo publikacji, która nie zawiera całego materiału. Jeśli chcesz kupić pełną wersję kliknij tutaj',
    more_buy_subscriptions : 'Możesz również kupić dostęp do wszystkich publikacji. Jeśli chcesz kupić subskrypcję kliknij tutaj',
    search: 'Szukaj',
    lack: 'Brak',
    save: 'Zapisz',
    buy_access: 'Kup dostęp',
    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwieć',
    jule: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień',
    red: 'Czerwony',
    purple: 'Fioletowy',
    navy: 'Granatowy',
    blue: 'Niebieski',
    light_blue: 'Błękitny',
    green: 'Zielony',
    light_green: 'Jasno zielony',
    yellow: 'Żółty',
    orange: 'Pomarańczowy',
    pink: 'Różowy',
    brown: 'Brązowy',
    grey: 'Szary',
    add_new: 'Dodaj nowy',
    type_block_generator: 'Rodzaj bloczka',
    soccer: 'Piłka Nożna',
    futsal: 'Futsal',
    no_access_private: 'Brak dostępu do prywatnego materiału',
    no_access_private_description: 'Materiał został stworzony podczas pełnego dostępu do aplikacji w postacji subskrycpji. Jeśli chcesz znowu mieć dostęp do materiałów musisz wykupić pełny dostęp!',
    type_copied_2_outline: 'Materiał jest zablokowany. Twoja subskrypcja wygasła, a konspekt został stworzony z płanego dostępu',
    type_copied_2_exercise: 'Materiał jest zablokowany. Twoja subskrypcja wygasła, a ćwiczenie zostało stworzone z płanego dostępu',
    type_copied_1: 'Materiał jest dostępny. Został stworzony z materiału do ktore jest wykupiony dożywotni dostęp',
    type_copied: 'Materiał skopiowany z publikacji trenerskich'
  },
  tactic: {
    field: 'Boisko',
    tools: 'Narzędzia',
    coach: 'Trener',
    goalkeeper: 'Bramkarz',
    area_drawing: 'Rysowanie obszaru',
    eraser: 'Gumka',
    ball: 'Piłla',
    player_cross: 'Zawodnik krzyć',
    player_circle: 'Zawodnik okrąg',
    change_marker: 'Zmiana koloru markera,',
    futsal_red: 'Futsal czerwone',
    futsal_white: 'Futsal białe',
    field_green: 'Boisko zielone',
    field_white: 'Boisko białe'
  },
  generator: {
    training_blocks_generator: 'Generator bloczków trenerskich',
    training_blocks_generator_description: 'Generator bloczków trenerskich to darmowa aplikacja, która umożliwia wgranie dowolnego logo pod wybrany wzór przez każdego trenera piłki nożnej. Dostępnych jest aktualnie do wyboru 18 wersji bloczków treningowych do których można wgrać dowolną grafikę lub logo swojego klubu piłkarskiego.',
    logo: 'Logo (zalecane wymiary 700px x 700px)',
    choose: 'Wybierz bloczek trenerski',
    save_pdf: 'Zapisz PDF',
    error: 'Jeśli napotkałeś błędy podczas działania aplikacji lub coś nie działa prawidłowo to',
  },
  tournament: {
    create: 'Stwórz turniej',
    create_description: 'Stwórz turniej - to darmowa aplikacja, która umożliwia stworzenie własnego turnieju wprowadzając dowolną liczbę drużyn i boisk.',
    logo: 'Logo (zalecane wymiary 700px x 700px)',
    brand: 'Logo organizacji (zalecane wymiary 700px x 700px)',
    title: 'Tytuł',
    description: 'Opis',
    organizer: 'Organizator',
    city: 'Miasto',
    place: 'Miejsce',
    date: 'Data',
    day_of_the_week: 'Dzień tygodnia',
    start_time: 'Godzina rozpoczęcia',
    number_of_teams: 'Ilość drużyn',
    team: 'Drużyna',
    field_1: '1 boisko',
    field_2: '2 boiska (minimum 4 zespoły)',
    field_3: '3 boiska (minimum 6 zespołów)',
    field_4: '4 boiska (minimum 8 zespołów)',
    field_5: '5 boisk (minimum 10 zespołów)',
    field_6: '6 boisk (minimum 12 zespołów)',
    field_7: '7 boisk (minimum 14 zespołów)',
    field_8: '8 boisk (minimum 16 zespołów)',
    field: 'Boisko',
    match_length: 'Długość meczu',
    break_between_matches: 'Przerwa między meczami',
    prepare: 'Przygotuj turniej',
    export: 'Turniej stworzył się poprawnie. Pod tabelą jest przycisk, który pozwoli zapisać turniej do pliku.',
    when: 'Kiedy',
    break: 'Przerwa',
    time: 'Godzina',
    save: 'Zapisz turniej',
    error: 'Jeśli napotkałeś błędy podczas działania aplikacji lub coś nie działa prawidłowo to',
  },
  books: {
    list: 'Lista publikacji',
    count: 'Ilość publikacji',
    category: 'Kategoria',
    library: 'Biblioteka',
    search: 'Szukaj',
    type: 'Rodzaj',
    partners: 'Partner',
    lang: 'Język',
    full: 'Pełna wersja',
    full_extend: 'Dostęp jest do końca okresu subskrypcji chyba, że masz wykupiony pojedyńczy dostęp do publikacji',
    auto_extend: 'Publikacja jest regularnie aktualizowana o nowe materiały',
    demo: 'Demo',
    noSearch: 'Brak publikacji w języku angielskim',
    U6U7: 'Skrzat (U6/U7)',
    U8U9: 'Żak (U8/U9)',
    U10U11: 'Orlik (U10/U11)',
    U12U13: 'Młodzik (U12/U13)',
    U14U15: 'Trampkarz (U14/U15)',
    U16U17: 'Junior młodszy (U16/U17)',
    U18U19: 'Junior starszy (U18/U19)',
    S: 'Senior (S)',
    game: 'Małe gry',
    motor: 'Motoryka',
    intelligence: 'Rozwój inteligencji piłkarskiej',
    futsal: 'Futsal',
    a_collection_of_outlines: 'Zbiór konspektów',
    other: 'Inne',
    player: 'Zawodnik',
    individual: 'Indywidualny',
    auto: 'Aktualizowany',
    outline: 'Konspekt',
    exercise: 'Ćwiczenie',
    material_base: 'Materiały w bazie',
    empty_materials: 'Brak materiałów spełniających kryteria wyszukiwania',
    empty_publications: 'Brak publikacji spełniających kryteria wyszukiwania',
    change_filter: 'Zmień filtry wyszukiwania',
    lack_materials: 'Brak kupionych materiałów',
    preview: 'Podgląd',
    search_exercise: 'Szukaj ćwiczenie',
    exercise_name: 'Nazwa ćwiczenia',
    start: 'Początek',
    table_of_contents: 'Spis treści',
    previous: 'Poprzednie',
    next: 'Następne',
    marked: 'Ćwiczenia oznaczone jako przeczytane',
    done_true: 'Oznacz jako przeczytane',
    done_false: 'Odznacz jako przeczytanie',
    favorite_true: 'Dodaj do ulubionych',
    favorite_false: 'Usuń z ulubionych',
    no_access_books_1: 'Pełna wersja aplikacji ze wszystkimi konspektami jest dostępna w naszym sklepie w formie pojedyńczego zakupu lub kupienia dostępu w formie subskrypcji na określony czas.',
    no_access_books_2: 'Zapraszamy również do odwiedzenia naszej strony www',
    book_current_translated: 'Publikacja jest w trakcie tłumaczenia',
    book_pl: 'Dostępna w języku polskim',
    book_en: 'Dostępna w języku angielskim',
  },
  outline: {
    part: 'Część',
    introduction: 'Wstępna',
    introduction1: 'Wstępna I',
    introduction2: 'Wstępna II',
    main1: 'Główna I',
    main2: 'Główna II',
    final: 'Końcowa',
    outlines: 'Konspekty',
    exercises: 'Ćwiczenia',
    logo: 'Logo',
    add: 'Dodaj',
    title: 'Tytuł',
    subject: 'Temat',
    description: 'Opis',
    coach: 'Trener',
    assistant: 'Asystent',
    microcycle: 'Mikrocykle',
    category: 'Kategoria',
    noCategories: 'Brak kategorii',
    objective: 'Cel',
    coachingPoints: 'Punkty coachingowe',
    no: 'Nr zajęć',
    mesocycle: 'Mezocykl',
    technique: 'Technika / działania indywidualne',
    motorSkills: 'Motoryka',
    group: 'Działania grupowe / zespołowe',
    knowledge: 'Wiedza',
    gameGoal: 'Cele gry',
    actions: 'Działania',
    gameRules: 'Zasady gry',
    motorGoal: 'Cel motoryczny',
    date: 'Data',
    hour: 'Godzina',
    time: 'Czas',
    place: 'Miejsce',
    trainingPlace: 'Miejsce treningu',
    numberOfBalls: 'Ilość piłek',
    numberOfGoals: 'Ilość bramek',
    numberOfMarkers: 'Ilość oznaczników',
    numberOfCones1: 'Ilość stożków',
    numberOfCones2: 'Ilość pachołków',
    numberOfPoles: 'Ilość tyczek',
    numberOfLadders: 'Ilość drabinek',
    ageCategory: 'Kategoria wiekowa',
    workTime: 'Czas zajęć',
    numberOfPlayers: 'Liczba zawodników',
    balls: 'Piłki',
    goals: 'Bramki',
    markers: 'Oznaczniki',
    cones1: 'Stożki',
    cones2: 'Pachołki',
    poles: 'Tyczki',
    ladders: 'Drabinki',
    otherAccessories: 'Inne przybory',
    trainingAccessories: 'Przybory treningowe',
    areas: 'Obszary',
    addittionalInformations: 'Dodatkowe informacje',
    modifications: 'Modyfikacje',
    exercise: 'Ćwiczenie',
    rememberCoach: 'Trenerze pamiętaj',
    descriptionMod: 'Opis modyfikacji',
    addExercise: 'Dodaj ćwiczenie',
    printPdf: 'Drukuj pdf',
    save: 'Zapisz',
    introductionName: 'Część wstępna',
    introduction1Name: 'Część wstępna I',
    introduction2Name: 'Część wstępna II',
    mainName: 'Część główna',
    main1Name: 'Część główna I',
    main2Name: 'Część główna II',
    finalName: 'Część końcowa',
    notes: 'Uwagi/Notatki',
    addPrivateExercise: 'Dodaj ćwiczenie z Twojej bazy',
    addExerciseOnBooks: 'Dodaj ćwiczenie z Twoich publikacji',
    showInfoOutline: 'Pokaż / ukryj informacje o treningu w konspekcie',
    showPartOutline: 'Pokaż / ukryj części w treningu w konspekcie',
    showInfoExercise: 'Pokaż / ukryj informacje o treningu',
    showPartExercise: 'Pokaż / ukryj części w treningu',
    partName: 'Ćwiczenie',
    brand: 'Brand',
    zoomPlus: 'Przybliżenie',
    zoomMinus: 'Oddalenie',
    previewMode: 'Podgląd konspektu',
    goToEdit: 'Wróc do edycji',
    goToTab: 'Wróć do standardowego widoku',
    goToPrivateOutline: 'Dodaj konspekt do prywatnych materiałów',
    name: 'Nazwa',
    qualityNotes: 'Uwagi jakościowe',
    settings: 'Auto ustawienia konspektów',
    settings_description: 'Dane w tym miejscu, będą się automatycznie uzupełniały w każdym nowym konspekcie, aby przyspieszyć proces tworzenia konspektu.',
    settings_save: 'Zapisz konfigurację',
    save_is_authenticated: 'Możliwość zapisania konspektu jest tylko dla użytkowników, którzy posiadają konto w aplikacji. Załóż konto teraz!',
  },
  base: {
    your_base: 'Twoja baza materiałów',
    your_outlines: 'Twoje konspekty',
    your_exercises: 'Twoje ćwiczenia',
    your_microcycles: 'Twoje mikrocykle',
    category_outlines: 'Kategorie prywatne konspekty',
    category_exercises: 'Kategorie prywatne ćwiczenia',
    category_microcycles: 'Kategorie prywatne mikrocykle',
    space: 'Miejsce',
    count: 'Ilość',
    private_outlines: 'Prywatne konspekty',
    empty_outlines: 'Brak konspektów w bazie',
    private_exercises: 'Prywatne ćwiczenia',
    empty_exercises: 'Brak ćwiczeń w bazie',
    search_exercise: 'Szukaj ćwiczenie',
    exercise_name: 'Nazwa ćwiczenia',
    new_category: 'Nowa kategoria',
    add_category: 'Dodaj kategorię',
    category: 'Kategoria',
    color_category: 'Kolor kategorii',
    color_group: 'Kolor grupy',
  },
  auth: {
    login_page: 'Logowanie',
    email: 'Email',
    password: 'Hasło',
    login_link: 'Zaloguj się',
    forgot_password_link: 'Zapomniałeś hasło',
    register_link: 'Rejestracja',
    register_page: 'Rejestracja',
    register_page_description: 'Konto tworzy się automatycznie po podaniu adresu email.',
    create_account: 'Stwórz konto',
    register_created: 'Konto zostało stworzone prawidłowo. Hasło do zalogowania zostało wysłane na emaila. Po zalogowaniu można je zmienić w zakładce konto.',
    register_exist: 'Posiadasz już konto w aplikacji. Zaloguj się teraz lub przypomnij hasło',
    forgot_page: 'Przypomnienie hasła',
    reset_password: 'Resetuj hasło',
    new_password: 'Nowe hasło',
    confirm_password: 'Potwierdź nowe hasło',
    set_new_password: 'Ustaw nowe hasło',
    reminder_password: 'Przypomnienie hasła',
    reminder_password_description: 'Na podany adres email został wysłany link aktywacyjny, w który należy wejść w celu ustawienia nowego hasła do konta.',
    back_login: 'Wróc do logowania',
    forgot_success_page: 'Hasło zostało ustawione',
    forgot_success_page_description: 'Od teraz przy logowaniu będziesz używać nowego hasła.',
  },
  club: {
    count: 'Ilość',
    settings: 'Ustawienia',
    coaches: 'Trenerzy',
    date_of_birth: 'Data urodzin',
    coach: 'Trener',
    add_coach: 'Dodanie trenera',
    email: 'Email',
    email_coach: 'Email trenera',
    additional_groups: 'Dodatkowe grupy',
    group_main: 'Główna grupa',
    empty_group: 'Brak grup',
    empty_coach_group: 'Brak trenerów w grupie',
    buy_space_coaches: 'Kup teraz dodatkowe miejsce dla trenera',
    need_create_group: 'Najpierw musisz stworzyć grupę',
    first_name: 'Imię',
    last_name: 'Nazwisko',
    your_first_name: 'Twoje imię',
    your_last_name: 'Twoje nazwisko',
    groups: 'Grupy',
    add_coach_to_club: 'Dodaj trenera do klubu',
    update_coach_details: 'Aktualizuj dane trenera',
    information_board: 'Terminarz treningów',
    information_board_page: 'Terminarz treningów dla',
    group: 'Grupa',
    group_link: 'Link do grupy',
    embedded_board: 'Kod do osadzenia na stronie',
    board_settings: 'Ustawienia terminarza treningów',
    board_settings_description: 'Dane w tym miejscu, będą się automatycznie pokazywały w terminarzu treningów.',
    bank_account_number: 'Numer konta bankowego',
    description_to_the_account_number: 'Opis do numeru konta',
    facebook: 'FB fanpage',
    instagram: 'Instagram',
    youtube: 'Youtube',
    save_settings: 'Zapisz ustawienia',
    start_range: 'Zakres start',
    end_range: 'Zakres koniec',
    statistics: 'Statystyki',
    see_statistics: 'Zobacz statystyki',
    no_statistics: 'Brak statystyk dla danej grupy.',
    change_group: 'Zmień grupę lub zakres dat.',
    player: 'Zawodnik',
    frequency: 'Frekwencja',
    count_player_training: 'Ilość zawodników na treningu',
    training_attendance: 'Frekwencja na treningu',
    has_a_telephone_number: 'Posiada numer telefonu',
    count_player_dues: 'Ilość zapłaconych składek',
    manager_attendance: 'Zarządzanie obecnościami',
    list_reported_absences: 'Zgłoszone nieobecności',
    list_completed_absences: 'Odrobionione nieobecności',
    add: 'Dodaj',
    lack: 'Brak',
    reported_absence: 'Zgłoszona nieobecność',
    calendar: 'Kalendarz',
    training: 'Trening',
    private_outline: 'Prywatny konspekt',
    outline_link: 'Link do konspektu',
    view_attendance: 'Zobacz obecność - frekwencja',
    empty_trainings: 'Brak treningów',
    add_training: 'Dodaj trening',
    new_training: 'Nowy trening',
    microcycle: 'Mikrocykl',
    no_events_days: 'Brak wydarzeń tego dnia',
    mesocycle: 'Mezocykl',
    area_technique: 'Obszar techniczno taktyczny',
    area_motor: 'Obszar motoryczny',
    area_mental: 'Obszar mentalny',
    area_knowledge: 'Obszar wiedzy',
    your_groups: 'Twoje grupy',
    name: 'Nazwa',
    color_group: 'Kolor grupy',
    premium_amount: 'Kwota składki',
    description: 'Opis',
    save: 'Zapisz',
    new_group: 'Nowa grupa',
    create_new_group: 'Stwórz nową grupę',
    empty_groups: 'Brak boisk',
    pitches_list: 'Lista boisk',
    pitch: 'Boisko',
    name_pitch: 'Nazwa boiska',
    empyt_pitches: 'Brak boisk',
    new_pitch: 'Nowe boisko',
    putch: 'Boisko',
    add_pitch: 'Dodaj boisko',
    street: 'Ulica',
    city: 'Miasto',
    save_pitch: 'Zapisz boisko',
    dues_list: 'Lista składek',
    empty_dues: 'Brak składek',
    date_of_payment: 'Termin płatności',
    premium_payment_date: 'Data płatności składki',
    new_dues: 'Nowa składka',
    generate_dues: 'Generuj składkę',
    amount_dues: 'Kwota składki',
    players: 'Zawodnicy',
    dues: 'Składka',
    save_dues: 'Składka',
    dues_for: 'Składka za',
    players_list: 'Lista zawodników',
    empty_players_group: 'Brak zawodników w grupie',
    phone_number: 'Numer telefonu',
    notes: 'Notatki',
    zip_code: 'Kod pocztowy',
    add_player_group: 'Dodaj zawodnika do grupy',
    new_player: 'Nowy zawodnik',  
    trainings: 'Treningi',
    place_training: 'Miejsce treningu',
    start_time: 'Godzina rozpoczęcia',
    end_time: 'Godzina zakończenia',
    training_date: 'Data treningu',
    empty_trainings_group: 'Brak treningów w grupie',
    subject: 'Temat',
    add_new_training: 'Nowy nowy trening',
    advanced_settings: 'Ustawienia zaawansowane',
    add_training_in_each: 'Dodaj trening w każdym',
    week: 'tygodniu',
    month: 'miesięcu',
    next_period: 'przez najbliższe (okresy)',
    link: 'Link',
    lack_group: 'Brak grupy',
    new_attendance: 'Nowa obecność',
    attendance: 'Obecność',
    check_attendance: 'Sprawdź obecność',
    list_attendance: 'Lista obecności',
    list_dues: 'Lista składek',
    type_stats: 'Rodzaj statystyki', 
    save_attendance: 'Zapisz obecność',
    planning_trainings: 'Plany treningowe',
    empty_planning_trainings: 'Brak planów treningowych',
    new_planning_training: 'Nowy plan treningowy',
    planning_training: 'Plan treningowy',
    start_date_mesocycle: 'Data rozpoczęcia mezocyklu',
    end_date_mesoccle: 'Data zakończenia mezocuklu',
    end_date_mesoccle_base_start: 'Data zakończenia mezocyklu na bazie daty rozpoczęcia mezocyklu',
    color: 'Kolor',
    create_new_planning_training: 'Stwórz nowy plan treningowy',
    need_add_player_to_group: 'Najpierw musisz dodać zawodników do grupy',
    new_pzpn: 'Zgodny z certyfikatem szkolenia PZPN',
    status_player: 'Status zawodnika',
    status_active: 'Aktywny',
    status_not_active: 'Nieaktywny',
  },
  wallet: {
    wallet_page: 'Portfel',
    withdraw: 'Wypłata',
    balance: 'Saldo',
    total_amount_paid_out: 'Total amount paid out',
    count_payments: 'Ilość wypłat',
    date_payment: 'Data płatności',
    withdraw: 'Wypłata',
    status: 'Status',
    status_1: 'Oczekuje na dodanie faktury',
    status_2: 'Potrzebna korekta',
    status_3: 'W trakcie przetwarzania',
    status_4: 'Płatność zrealizowana',
    no_data: 'No data',
    history: 'Historia',
    count: 'Ilość',
    amount: 'Kwota',
  },
  payment: {
    payment_page: 'Dostęp do wszystkich konspektów i ćwiczeń!',
    payment_page_description: 'Kupując subskrycpję na wybrany okres czasu otrzymujesz dostęp do wszystkich publikacji, które znajdują się platformie Soccer Sessions. Nowe dodawane konspekty i ćwiczenia będą od razu dostępne.',
    choice_currency: 'Wybierz walutę',
    pln_currency: 'polski złoty',
    choice_plan: 'Wybierz plan',
    plan_1: 'Dostęp na 1 miesiąc',
    plan_3: 'Dostęp na 3 miesiące',
    plan_6: 'Dostęp na 6 miesięcy',
    plan_12: 'Dostęp na 12 miesięcy',
    choice_company: 'Dane rozliczeniowe (tylko dla firm)',
    view_company_details: 'Zobacz dane firmowe',
    company_data: 'Dane firmowe',
    company: 'Nazwa / firma',
    street: 'Ulica',
    zip_code: 'Kod pocztowy',
    city: 'Miasto',
    country: 'Kraj',
    vat: 'Numer NIP',
    save_company: 'Zapisz dane firmy',
    payment_warning: 'Twój plan będzie automatycznie odnawiany, ale będziesz mógł anulować subkskrypcję w dowolnym momencie.',
    pay: 'Zapłać teraz',
    success_page: 'Płatność została przyjęta',
    success_page_description: 'Od teraz możesz korzystać z płatnych materiałów w aplikacji',
    failed_page: 'Płatność nie powiodła się',
    failed_page_description: 'Powodem może być nie wystarczająca ilość pieniędzy na koncie lub anulowanie płatności, musisz skontaktować się z supportem (contact@soccer-sessions.com) lub ze swoim bankiem w celu wyjaśnienia.',
    retry_payment: 'Ponów płatność'
  },
  account: {
    publications: 'Publikacje',
    active: 'Aktywne',
    during_subscription: 'w okresie subskrypcji',
    for_life: 'dożywotnio',
    view_publications: 'Zobacz kupione publikacje',
    none_purchased: 'Brak kupionych',
    publication: 'publikacji',
    list_publications: 'Lista kupionych publikacji',
    subscriptions: 'Subskrypcje',
    active_until: 'Aktywne do',
    no_active_subscriptions: 'Brak aktywnych subskrycpji',
    view_orders: 'Zobacz zamówienia',
    list_subscriptions: 'Lista zamówionych subskrypcji',
    invoice_prepare: 'Faktura w trakcie przygotowywania. Będzie dostępna do pobrania w ciągu kilku dni.',
    only_subscriptions: 'Tutaj znajdują się tylko zamówienia związane z subskrypcją.',
    date: 'Data',
    amount: 'Kwota',
    status: 'Status',
    status_1: 'Płatność zrealizowana prawidłowo',
    status_2: 'Błąd podczas płatności',
    empty_orders: 'Brak zamówień',
    private_outlines: 'Prywatne konspekty',
    training_planning: 'Planowanie treningu',
    crm: 'CRM',
    additions: 'Dodatki',
    coach_club: 'Trener w klubie',
    basic_information: 'Podstawowe informacje',
    email: 'Email',
    email_placeholder: 'Twój email',
    club: 'Nazwa klubu',
    club_placeholder: 'Nazwa Twojego klubu',
    name: 'Imię',
    name_placeholder: 'Twoje imię',
    last_name: 'Twoje nazwisko',
    last_name_placeholder: 'Twoje nazwisko',
    phone_number: 'Telefon',
    phone_number_placeholder: 'Numer telefonu',
    birth: 'Data urodziń',
    currency: 'Waluta',
    update_data: 'Aktualizuje dane',
    password_change: 'Zmiana hasła',
    old_password: 'Stare hasło',
    new_password: 'Nowe hasło',
    confirm_password: 'Potwierdź nowe hasło',
    change_password: 'Zmień hasło',
    subscription_cancellation: 'Anulowanie subskrypcji',
    cancel_your_subscription: 'Anuluj subskrypcje',
    deleting_accounts: 'Usuwanie konta',
    deleting_accounts_description: 'Szkoda, że chcesz usunąć konto w portalu Konspekty Piłka Nożna. Proszę wysłać wiadomość na contact@soccer-sessions.com z prośbą o usunięcie konta. Support zamknie konto w ciągu 5 dni roboczych.',
    delete_account: 'Usuń konto'
  },
  best_coach: {
    header_primary_1: 'Najlepsi trenerzy,',
    header_primary_2: 'są przygotowani do zajęć każdego dnia',
    section_primary_1: 'Zainwestuj w rozwój swojej kariery trenerskiej dzięki abonamentowi na materiały dostępne na platformie Soccer Sessions!',
    section_primary_2: 'W jednym miejscu zgromadziliśmy konspekty i ćwiczenia treningowe od największych marek trenerskich w Polsce, dostarczając Ci kompleksowy zbiór ponad 2300 materiałów podzielonych na kategorie. To nie tylko treści, to współpraca z liderami branży, widoczna także w naszym imponującym portfolio partnerów',
    buy_now: 'Kup teraz',
    buy_right_now: 'Kup dostęp już teraz',
    buy_right_now_2: 'Kup dostęp już teraz',
    see_more: 'Dowiedz się więcej',
    buy_publications: 'Kup dostęp do publikacji',
    header_about_1: 'O nas Soccer Sessions',
    header_about_2: 'Soccer Sessions',
    section_about_1: 'Dołącz do ponad 3000 trenerów, którzy już korzystają na co dzień z naszych publikacji. Dla Soccer Sessions każdy trener, niezależnie od poziomu doświadczenia, znajdzie cenne narzędzia do tworzenia idealnych jednostek treningowych, umożliwiających pełny rozwój ich zawodników.',
    section_about_2: 'Soccer Sessions to również partnerstwo z Asystentem Trenera, projektem skierowanym do wszystkich trenerów pracujących z dziećmi i młodzieżą. Niezależnie czy trenujesz skrzaty, żaki, orliki czy młodziki, nasza platforma jest dostosowana do Twoich potrzeb. Znajdziesz u nas sprawdzone konspekty i ćwiczenia treningowe, które skutecznie wspierają Twoje zajęcia. Dołącz już dziś i odkryj, jak Soccer Sessions może zrewolucjonizować Twoje treningi!',
    section_about_3: 'Platforma',
    section_about_4: 'Odkryj ponad 70 publikacji oraz imponującą liczbę 2300 konspektów i ćwiczeń, tworząc kompleksową bazę wiedzy dla każdego trenera piłkarskiego.',
    section_about_5: 'Baza prywatnych materiałów',
    section_about_6: 'Nasza aplikacja umożliwia tworzenie własnych konspektów i ćwiczeń za pomocą modułu Prywatne Konspekty. Dzięki zgodności z Certyfikatem Szkolenia PZPN, masz pewność, że tworzone materiały są zgodne z najwyższymi standardami trenerskimi. Prosta i szybka metoda tworzenia konspektów daje Ci kontrolę nad treściami, a możliwość wydruku lub zapisania ich w formie pliku PDF sprawia, że są gotowe do użycia na każdych zajęciach.',
    section_about_7: 'Społeczność',
    section_about_8: 'Dołącz do ponad 3000 użytkowników korzystających z naszej aplikacji. Jesteśmy dumni z współpracy z największymi partnerami w Polsce oraz z aktywnego wsparcia grupy Asystent Trenera. Razem tworzymy silną społeczność, która inspiruje i rozwija umiejętności trenerskie.',
    section_about_9: 'Rozwijaj się razem z nami na platformie Soccer Sessions - Twoim niezbędnym narzędziu do doskonalenia treningów piłkarskich!',
    quote: 'Niezwykłe osiągnięcia zawodników są rezultatem nie tylko ich własnego talentu, ale także doskonałej pracy trenera. Kiedy trener jest solidnie przygotowany do treningu, rozwój zawodników staje się nieunikniony.',
    header_product_1: 'Subskrypcja Soccer Sessions',
    section_product_1: 'Dołącz do naszej dynamicznej społeczności i odkryj unikalną ofertę, która otworzy przed Tobą dostęp do ponad 70 ekskluzywnych publikacji trenerskich o łącznej wartości ponad 3500 złotych. To niepowtarzalna propozycja na rynku, umożliwiająca korzystanie z ponad 2300 konspektów i ćwiczeń. W ramach subskrypcji, zyskujesz także możliwość tworzenia własnych materiałów w aplikacji Prywatne Konspekty, gdzie edytujesz, drukujesz, a nawet zapisujesz je do formatu PDF.',
    section_product_2: 'Wśród partnerów trenerskich są takie marki jak:',
    section_product_3: 'Nasza baza obejmuje wszystkie grupy wiekowe: skrzat, żak, orlik, młodzik, trampkarz, junior młodszy, junior starszy oraz senior. Ponadto, specjalne kategorie takie jak małe gry, motoryka, rozwój inteligencji czy futsal zapewniają pełen zakres treningów dla różnych potrzeb. Dzięki intuicyjnym filtrom na stronie, znajdziesz szybko i łatwo materiały dostosowane do Twojej grupy.',
    section_product_4: 'To jednak nie wszystko.',
    section_product_5: 'Przestań się męczyć z zamkniętymi PDF-ami! Nasza aplikacja oferuje moduł Prywatne Konspekty, gdzie trenerzy mogą swobodnie korzystać z materiałów z bazy, modyfikować je, zapisywać, drukować i eksportować do formatu PDF. Wszystko zgodnie z certyfikacją szkolenia PZPN.',
    section_product_6: 'Subskrypcja obejmuje nie tylko dostęp do obecnej bazy, ale także darmowy dostęp do nowych publikacji, które regularnie dodajemy do Soccer Sessions. W 2023 roku wprowadziliśmy aż 39 nowych materiałów, co średnio daje 3 publikacje miesięcznie.',
    section_product_7: 'Przekonaj się, jak Soccer Sessions może zrewolucjonizować Twoje treningi. Dołącz już dziś i ciesz się kompleksowym wsparciem dla Twojej pracy trenerskiej!',
    header_advantage_1: 'Najważniejsze korzyści z bazy Soccer Sessions',
    section_advantage_1: 'Dostęp do ponad 70 publikacji (ponad 2300 konspektów i ćwiczeń)',
    section_advantage_2: 'Dostęp 24/7 na każdym urządzeniu mobilnym z możliwością zrobienia aplikacji mobilnej na pulpicie Twojego telefonu',
    section_advantage_3: 'Możliwość tworzenia, własnych konspektów i ćwiczeń na bazie publikacji z bazy',
    section_advantage_4: 'Nieograniczony dostęp nie tylko do istniejących, ale również nowych publikacji',
    section_advantage_5: 'Oszczędność pieniędzy. Koszt abonamentu rocznego to niewiele ponad ... złotówka dziennie',
    section_advantage_6: 'W bazie znajduje się kilka publikacji, które są dostępne jedynie w formie subskrypcji. Co więcej, są one wiecznie żywe, gdyż regularnie zostaną uzupełniane o nowe konspekty i ćwiczenia w określonych odstępach czasowych.',
    header_publications: 'Pełna lista publikacji:',
    section_publications: 'Kolejne publikacje w trakcie przygotowywania!',
    header_faq: 'Pytania przed zakupem?',
    section_faq_1: 'Czy dostanę fakturę VAT na firmę?',
    section_faq_2: 'Tak, wystarczy przy zakupie podać dane firmy lub po zakupie zgłosić się do nas. Fakturę VAT w formie PDF umieścimy w specjalnie przygotowanym widoku w aplikacji, gdzie będzie możliwość pobrania jej na dysk. Jeśli potrzebujesz faktury proforma, napisz do nas na',
    section_faq_3: 'W jaki sposób mogę opłacić zamówienie?',
    section_faq_4: 'Płatności obsługiwane są przez system Stripe, który udostępnia możliwość płatności kartą.',
    section_faq_5: 'Czy jest limit obejrzeń materiałów?',
    section_faq_6: 'Nie. Materiałów możesz korzystać nieograniczoną ilość razy, o dowolnej porze, na wybranym przez siebie urządzeniu.',
    section_faq_7: 'Co jeśli mam już wykupiony dostęp do kilku publikacji?',
    section_faq_8: 'Jeśli wcześniej wykupiłeś/aś dostęp do jakiejkolwiek publikacji, to nadal masz do niej dostęp. Subskrypcja obejmuje dostęp do wszystkich publikacji w bazie. Po zakończeniu subskrypcji lub rezygnacji nadal masz dostęp do wcześniej wykupionych pojedynczych publikacji.',
    section_faq_9: 'Jak wygląda kwestia przedłużenia abonamentu o kolejny rok?',
    section_faq_10: 'Następuje to automatycznie, na podstawie danych podanych podczas pierwszego zamówienia subskrypcji, które możesz w każdym momencie anulować.',
    section_faq_11: 'Czy platforma oferuje wsparcie techniczne lub pomoc w przypadku problemów z dostępem?',
    section_faq_12: 'Oczywiście, w przypadku jakichkolwiek problemów technicznych, zapewnimy odpowiednie wsparcie. Nasz zespół programistyczny monitoruje wydajność aplikacji i regularnie eliminuje wszelkie błędy.',
    section_faq_13: 'Jak często dodawane są nowe materiały do platformy?',
    section_faq_14: 'Nasza baza publikacji stale się rozwija. W 2023 roku dodaliśmy 39 publikacji, co oznacza, że co 10 dni pojawiła się nowa publikacja.',
    section_faq_15: 'Jeśli masz dodatkowe pytania dotyczące abonamentu, faktury lub obsługi zamówienia, śmiało napisz na adres',
    section_faq_16: 'Jesteśmy gotowi pomóc!',
    header_app: 'Zobacz jak działa aplikacja',
    what_is_sub: 'Subskrypcja'
  },
  sms: {
    body: 'Treść wiadomości',
    new_sms: 'Nowy SMS',
    send_sms: 'Wyślij SMS'
  },
  popover: {
    calendar: 'Pełny kalendarz treningów dla wybranej grupy. Dzięki opcji przełączania się między miesiącami i dniami, istnieje możliwość dodawania lub edycji treningów. Możesz sprawdzić obecność oraz frekwencję na zajęciach za pomocą odpowiednich funkcji. Dodatkowo, po dodaniu i ustawieniu planów treningowych dla każdej grupy, ich harmonogram będzie także wyświetlany na kalendarzu.',
    coaches: 'Lista trenerów w klubie, którzy mają dostęp do grup treningowych. Mogą dodawać, edytować i usuwać zawodników, treningi, składki, obecności oraz plany treningowe. Możliwe jest również usuwanie trenerów w dowolnym momencie.',
    information_board_page: 'Terminarz treningów dla danej grupy wiekowej, który można umieścić na stronie internetowej lub udostępnić w formie linku. Terminarz jest publiczny.',
    statistics: 'Pełna statystyka dla każdej grupy treningowej w dowolnym okresie czasu, prezentująca obecność zawodników na treningach lub opłacone składki w danym miesiącu.',
    your_groups: 'Miejsce do zarządzania grupami treningowymi w klubie. Można nadać kolor grupie, a następnie ten kolor będzie wyświetlany w kalendarzu. Praktycznie w każdym miejscu w CRM, mamy informację o aktualnie aktywnej grupie treningowej.',
    players: 'Zarządzanie zawodnikami w danej grupie. Każdy zawodnik może należeć do kilku grup treningowych. Posiada domyślną główną grupę. Edytując zawodnika, można uzupełnić jego informacje oraz zarządzać obecnościami poprzez zgłoszenie lub odrobienie treningu.',
    dues_list: 'Lista wygenerowanych składek dla danej grupy treningowej, w której zaznaczamy termin płatności składki oraz informację o tym, który zawodnik uiścił opłatę za określony okres.',
    pitches_list: 'Lista boisk treningowych w klubie, które można później wybierać podczas tworzenia treningu i wyświetlać w publicznym terminarzu treningów.',
    planning_trainings: 'Indywidualne plany treningowe dla każdej grupy, które są wyświetlane w kalendarzu do wglądu trenera, nad czym aktualnie pracuje dana grupa.',
    trainings: 'Lista treningów danej grupy. Trening zawiera datę, godzinę rozpoczęcia i zakończenia, oraz miejsce. Dodatkowo, można dodać konspekt. Trening wyświetlają się w kaledanrzu oraz publicznym treminarzu.',
    new_attendance: 'Sprawdzanie listy obecności. System podpowiada, który zawodnik jest nieaktywny w CRM lub zgłosił nieobecność na treningu.',
    new_sms: 'Możliwość wysłania sms dla grup treningowych z poziomu Twojego telefonu. System zbiera wszystkie numery telefonów zawodników w grupie i przygotowuje SMS. Funkcjonalność działa tylko na urządzeniu telefonicznym.',
  }
};